.groupContainer {
  margin-top: calc(18px * var(--scale-coefficient));
  max-width: 1550px;
  border: 1px solid var(--dark-cyan);
  border-radius: var(--base-border-radius);
}

@media (min-width: 1600px) {
  .groupContainer {
    margin-left: auto;
    margin-right: auto;
  }
}

.iconArrow {
  width: calc(22px * var(--scale-coefficient));
  height: calc(22px * var(--scale-coefficient));
  transition: ease-in-out 0.3s;
}
.iconArrowUp {
  transform: rotate(-180deg);
}

.counter {
  display: inline-flex;
  align-items: center;
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: calc(14px * var(--scale-coefficient));
  line-height: calc(16px * var(--scale-coefficient));
}

.counter__title {
  font-family: Manrope, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: calc(18px * var(--scale-coefficient));
  line-height: calc(20px * var(--scale-coefficient));
  max-width: calc(600px * var(--scale-coefficient));
  text-overflow: ellipsis;
  overflow: hidden;
}

.counterIcon {
  margin-left: calc(9px * var(--scale-coefficient));
  margin-right: calc(6px * var(--scale-coefficient));
}

.group:before {
  content: '';
  position: absolute;
  top: 50%;
  left: -2px;
  width: 2px;
  height: calc(30px * var(--scale-coefficient));
  background-color: var(--primary-color);
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  transform: translate(0, -50%);
}

@media all and (max-width: 768px) {
  .group {
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .counter__title {
    white-space: nowrap;
    max-width: 100px;
  }

  .counter__projects {
    white-space: nowrap;
  }
}

@media all and (max-width: 420px) {
  .counter__projects, .counterIcon {
    display: none;
  }
}
