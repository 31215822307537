.operation-node {
  width: calc(84px * var(--scale-coefficient));
  height: calc(84px * var(--scale-coefficient));
  background: var(--white);
  border-radius: 8px;
  border: 1px solid #DAE7ED;
  filter: drop-shadow(0px 4px 10px rgba(0, 0, 0, 0.06)) drop-shadow(0px 4px 10px rgba(58, 130, 228, 0.12));
  display: flex;
  align-items: center;
  justify-content: center;
}

.operation-node__selected {
  outline: 2px solid var(--primary-color);
}

.operation-node__content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.operation-node__disabled {
  opacity: 0.5;
}

.operation-node__disabled .react-flow__handle {
  opacity: 0;
}

.operation-node__icon-container {
  width: calc(32px * var(--scale-coefficient));
  height: calc(32px * var(--scale-coefficient));
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: calc(8px * var(--scale-coefficient));
}

.operation-node__text {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: calc(14px * var(--scale-coefficient));
  line-height: calc(18px * var(--scale-coefficient));
}


.operation-node:hover .button-delete-container {
  display: block;
  transform: translate(50%,-30%) scale(0.8);
}
