.save-result-panel {
  display: flex;
  flex-direction: column;
  background: var(--white);
  height: 100%;
  width: calc(920px * var(--scale-coefficient));
  position: absolute;
  right: 0;
  z-index: 100;
  box-shadow: 0px 4px 10px rgba(58, 130, 228, 0.12), 0px 4px 10px rgba(0, 0, 0, 0.06);
}
