.container {
    padding: calc(8px * var(--scale-coefficient)) calc(12px * var(--scale-coefficient)) 0;
}

.description {
    color: var(--black);
    font-size: calc(16px * var(--scale-coefficient));
    word-wrap: break-word;
    font-family: 'Manrope', 'Arial', sans-serif;
    font-weight: 500;
}
