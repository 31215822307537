.tabs-container {
    width: 100%;
    height: calc(46px * var(--scale-coefficient));
    background-color: var(--white);
    display: flex;
    cursor: pointer;
    border-radius: var(--base-border-radius);
}

.tabs-container__tab {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: calc(16px * var(--scale-coefficient));
    line-height: calc(20px * var(--scale-coefficient));
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--title-color);
    padding: calc(5px * var(--scale-coefficient));
    flex: 1;
    border: 1px solid #DAE7ED;
    user-select: none;
    text-align: center;
}

.tabs-container__tab_active, .tabs-container__tab_active.tabs-container__tab_chip {
    background-color: var(--primary-color);
    fill: white;
    color: var(--white);
    border: none;
}

.tabs-container__tab_active svg {
    fill: white;
}

.tabs-container__tab_first {
    border-bottom-left-radius: var(--base-border-radius);
    border-top-left-radius: var(--base-border-radius);
}

.tabs-container__tab_last {
    border-bottom-right-radius: var(--base-border-radius);
    border-top-right-radius: var(--base-border-radius);
}

.tabs-container__tab_chip {
    background: rgba(197, 217, 232, 0.15);
    border-radius: var(--base-border-radius);
    margin-right: calc(4px * var(--scale-coefficient));
    font-size: calc(14px * var(--scale-coefficient));
    line-height: calc(18px * var(--scale-coefficient));
    color: var(--dark-grey);
    border: none;
}

.tabs-container__tab_last.tabs-container__tab_chip {
    margin-right: 0;
}

.tabs-container__tab_chip:not(.tabs-container__tab_active):hover {
    background: rgba(58, 130, 228, 0.1);
    color: #3A82E4;
}
