.map-filter {
    background: var(--white);
    height: calc(100vh - var(--panel-size));
    width: calc(730px * var(--scale-coefficient));
    display: flex;
    flex-direction: column;
}

.map-filter__list {
    overflow: auto;
}

.map-filter__header {
    height: var(--panel-size);
    padding: 0 var(--modal-left-right-padding);
    display: flex;
    align-items: center;
    position: relative;
    justify-content: space-between;
    border-bottom: 1px solid var(--dark-cyan);
}

.map-filter__content {
    padding: var(--modal-bottom-top-padding) var(--modal-left-right-padding);
    flex: 1;
    min-height: 0;
    display: flex;
    flex-direction: column;
}

.map-filter__title {
    font-family: Manrope, sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: calc(18px * var(--scale-coefficient));
}

.map-filter__descr {
    font-size: calc(14px * var(--scale-coefficient));
    line-height: 1;
    color: #444;
    margin: calc(16px * var(--scale-coefficient)) 0 calc(20px * var(--scale-coefficient));
}

.map-filter__buttons {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: calc(10px * var(--scale-coefficient)) 0;
}

.map-filter__add-button-text {
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: calc(14px * var(--scale-coefficient));
    line-height: calc(14px * var(--scale-coefficient));
    color: var(--primary-color);
    border-bottom: 1px dashed var(--primary-color);
    text-transform: none;
}

.map-filter__delete-button-text {
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: calc(14px * var(--scale-coefficient));
    line-height: calc(14px * var(--scale-coefficient));
    color: var(--dark-grey);
    border-bottom: 1px dashed var(--dark-grey);
    text-transform: none;
}

.map-filter__button > span {
    display: flex;
    align-items: center;
}

.map-filter__button > span > span {
    line-height: 1;
    margin-left: 0;
    vertical-align: top;
}

.map-filter__field-type {
    margin-right: calc(10px * var(--scale-coefficient));
    vertical-align: middle;
    display: flex;
}

.map-filter__field-type-svg {
    vertical-align: middle;
}

.map-filter__field-name {
    display: inline-block;
    vertical-align: middle;
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: calc(14px * var(--scale-coefficient));
    line-height: calc(14px * var(--scale-coefficient));
    color: var(--title-color);
}

.map-filter__field-type {
    margin-left: calc(-12px * var(--scale-coefficient));
}

.map-filter__bottom-panel {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    padding: calc(18px * var(--scale-coefficient)) calc(20px * var(--scale-coefficient));
}
