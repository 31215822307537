.widget-layout {
  display: flex;
  background-color: var(--backdrop-color);
  height: calc(100vh - 2 * var(--panel-size));
}

.widget-content {
  flex-grow: 1;
  height: calc(100vh - 2 * var(--panel-size));
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.widget-layout:before {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: -webkit-repeating-radial-gradient(center center, var(--backdrop-item-color), var(--backdrop-item-color) 1px, transparent 2px, transparent 100%);
  -webkit-background-size: calc(24px * var(--scale-coefficient)) calc(24px * var(--scale-coefficient));
  -moz-background-size: calc(24px * var(--scale-coefficient)) calc(24px * var(--scale-coefficient));
  background-size: calc(24px * var(--scale-coefficient)) calc(24px * var(--scale-coefficient));
}

.widget-main {
  padding: calc(8px * var(--scale-coefficient)) calc(12px * var(--scale-coefficient));
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  height: calc(100% - 86px * var(--scale-coefficient));
  overflow: hidden;
}

.widget-main__preview {
  height: 100%;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.widget-main__preview-container {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  overflow: auto;
}

.widget-main__preview-container .MuiTooltip-popper {
  --scale-coefficient:1;
    --zoom: 100%;
}

.widget-main__title {
  font-family: 'Manrope', sans-serif;
  font-weight: bold;
  color: var(--black);
  margin-top: calc(16px * var(--scale-coefficient));
  margin-bottom: calc(14px * var(--scale-coefficient));
}

.widget-main__graphic {
  width: 100%;
  box-sizing: border-box;
  flex-grow: 1;
  height: calc(100% - 60px);
  display: flex;
  flex-direction: column;
  overflow: hidden;

  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.06),
    0 4px 10px rgba(58, 130, 228, 0.12);
  border-radius: 3px;
}

.widget-main__graphic.fullscreen {
  position: fixed;
  z-index: 999;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  height: 100%;
  background: var(--white);
  border: 1px solid #dae7ed;
}

.widget-main__graphic__nomargins {
  padding: 0;
}

.widget-main__graphic__nomargins .widget-main__chart-title,
.widget-main__graphic__nomargins .widget-main__chart-description {
  padding-left: 20px;
}

.widget-main__graphic__nomargins .widget-main__chart-title {
  padding-top: 15px;
}


.widget-main__chart-title {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
  display: flex;
  align-items: center;
  color: var(--title-color);
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  white-space: pre-wrap;
  word-break: break-word;
}


@media (max-width: 768px) {
  .widget-main__chart-title {
    font-size: 16px;
    line-height: 18px;
  }
}

.widget-main__chart-heading {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 0 20px 0 20px;
  min-height: 38px;
}

.widget-main__chart-description {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: #808080;
  margin: 5px 5px 5px 0px;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  width: 100%;
  overflow: hidden;
  white-space: pre-wrap;
  word-break: break-word
}

.widget-main__dropdown {
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.04), 0px 4px 10px rgba(58, 130, 228, 0.06);
  background-color: rgba(255, 255, 255, 0.9);
  border-radius: var(--base-border-radius);
  border: 1px solid var(--light-cyan);
}

.layout__no-header .widget-layout {
  height: calc(100vh - 2 * var(--panel-size));
}
