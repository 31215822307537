.no-groups-placeholder{
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1 1 auto;
}

.no-groups-placeholder__content {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.no-groups-placeholder__icon-size-wrapper svg {
    max-width: none !important;
    max-height: none !important;
}

.no-groups-placeholder__title {
    font-family: 'Manrope', serif;
    font-style: normal;
    font-weight: 700;
    color: var(--title-color);
    font-size: calc(14px * var(--scale-coefficient));
    margin-top: calc(16px * var(--scale-coefficient));
}

.no-groups-placeholder__text {
    font-family: 'Roboto', serif;
    font-style: normal;
    font-weight: 400;
    color: var(--black);
    font-size: calc(14px * var(--scale-coefficient));
    margin-top: calc(6px * var(--scale-coefficient));
}
