.panel-item__container {
  border-radius: 4px;
  box-sizing: content-box;
}

.panel-item__popover {
  overflow-x: initial;
  overflow-y: initial;
}

.widget-dropdown__item {
  background-color: #dae7ed;
  border: 1px solid var(--dark-cyan);
  box-sizing: border-box;
  border-radius: var(--base-border-radius);
  margin: calc(3px * var(--scale-coefficient));
  cursor: pointer;
  position: relative;
  min-height: calc(34px * var(--scale-coefficient));
  width: calc(250px * var(--scale-coefficient));
}

.widget-dropdown__item .panel-item__button--delete svg {
  width: calc(10px*var(--scale-coefficient))!important;
  height: calc(16px*var(--scale-coefficient))!important;
}

.widget-dropdown__item:hover {
  background-color: #d1e7f1;
}

.widget-dropdown-item__container {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

.widget-dropdown__item:hover > .button-delete-container {
  display: block;
  transform: translate(50%,-30%) scale(0.8);
}

.panel-item__type {
  min-width: calc(54px * var(--scale-coefficient));
  background: #edf1f8;
  color: var(--primary-color);
  padding: 0 calc(10px * var(--scale-coefficient));
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-right: 1px solid var(--dark-cyan);
  font-weight: 500;
  font-size: calc(14px * var(--scale-coefficient));
  text-transform: uppercase;
  border-radius: var(--base-border-radius) 0 0 var(--base-border-radius);
}

.panel-item__type:nth-child(3), .panel-item__type:nth-child(4) {
  border-radius: 0;
}

.panel-item__type--icon {
  min-width: calc(32px * var(--scale-coefficient));
  padding: 0;
}

.panel-item__button-delete {
  display: none;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.panel-item__delete-aggregation:hover {
  color: #edf1f8;
}

.panel-item__delete-aggregation:hover:before {
  position: absolute;
  content: '';
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  outline: 1px solid var(--red-normal);
  border-radius: var(--base-border-radius);
}

.panel-item__delete-aggregation:hover .panel-item__button-filter {
  opacity: 0;
}

.panel-item__delete-aggregation:hover .panel-item__button-delete {
  display: flex;
}

.panel-item__value {
  margin: 0 calc(10px * var(--scale-coefficient));
  font-weight: 500;
  font-size: calc(14px * var(--scale-coefficient));
  line-height: calc(18px * var(--scale-coefficient));
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  flex: 1;
}

.panel-item__more {
  color: var(--dark-grey);
}

.panel-item__more--active {
  color: var(--title-color);
}

.panel-item__parent-options {
  position: relative;
  display: flex;
  align-items: center;
}

.panel-item__list-icon {
  margin-left: auto;
  width: calc(20px * var(--scale-coefficient));
  min-width: calc(20px * var(--scale-coefficient));
}

.panel-item__option--children {
  display: none;
  position: absolute;
  left: 100%;
  top: calc(-10px * var(--scale-coefficient));
}

.panel-item__option--parent:hover,
.panel-item__option--restrictions:hover{
  background-color: rgba(0, 0, 0, 0.08);
  cursor: pointer;
}

.panel-item__option--parent:hover .panel-item__option--children {
  display: block;
}

.panel-item__option--restrictions {
  cursor: pointer;
}
