.select-fields-panel {
  &__filter-section {
     padding: calc(20px * var(--scale-coefficient));
    display: flex;
    align-items: center;
  }
  &__filter-section-buttons {
    display: flex;
    justify-content: space-between;
  }
}
