.delete-dialog__body {
  text-align: center;
  padding: calc(24px * var(--scale-coefficient))
    calc(20px * var(--scale-coefficient)) calc(20px * var(--scale-coefficient));
}

.delete-dialog__title {
  margin: calc(6px * var(--scale-coefficient)) auto
    calc(16px * var(--scale-coefficient));
  font-family: 'Manrope', 'Arial', sans-serif;
  font-weight: bold;
  font-size: calc(18px * var(--scale-coefficient));
  line-height: calc(20px * var(--scale-coefficient));
  word-break: break-word;
}

.delete-dialog__subtitle {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: calc(16px * var(--scale-coefficient));
  line-height: calc(18px * var(--scale-coefficient));
  color: var(--black);
}

.delete-dialog__alert {
  width: 100%;
  max-height: calc(200px * var(--scale-coefficient));
  word-wrap: break-word;
  overflow-y: auto;
  margin-top: 20px;
  text-align: left;
}

.delete-dialog__alert .MuiAlert-message {
  min-width: 0;
}
