.heat-map-settings {
    background: var(--white);
    height: calc(100vh - var(--panel-size));
    width: calc(730px * var(--scale-coefficient));
    display: flex;
    flex-direction: column;
}

.heat-map-settings__header {
    height: var(--panel-size);
    padding: 0 var(--modal-left-right-padding);
    display: flex;
    align-items: center;
    position: relative;
    justify-content: space-between;
    border-bottom: 1px solid var(--dark-cyan);
}

.heat-map-settings__title {
    font-family: Manrope;
    font-style: normal;
    font-weight: bold;
    font-size: calc(18px * var(--scale-coefficient));
}

.heat-map-settings__content {
    padding: var(--modal-bottom-top-padding) var(--modal-left-right-padding);
    flex: 1;
    min-height: 0;
    display: flex;
    flex-direction: column;
}

.heat-map-settings__list {
    overflow: auto;
}

.heat-map-settings__gradient-text {
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: calc(14px * var(--scale-coefficient));
    line-height: calc(14px * var(--scale-coefficient));
    color: var(--title-color);
}

.heat-map-settings__gradient {
    border-radius: 10px;
    height: calc(10px * var(--scale-coefficient));
    flex: 1;
}

.heat-map-settings__block {
    min-height: 0;
    display: flex;
    flex-direction: column;
}

.heat-map-settings__block_inline {
    flex-direction: row;
    align-items: center;
}

.heat-map-settings__block_flex {
    flex: 1;
}

.heat-map-settings__block_inline > *:not(:last-child) {
    margin-right: calc(10px * var(--scale-coefficient));
}

.heat-map-settings__block_bottom {
    margin-bottom: calc(16px * var(--scale-coefficient));
}

.heat-map-settings__block_border {
    padding-top: calc(16px * var(--scale-coefficient));
    border-top: 1px solid var(--dark-cyan);
}
