.right-panel {

  &__close {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1;
  }

  &__content {
    height: 100%;
    padding: calc(16px * var(--scale-coefficient)) calc(20px * var(--scale-coefficient));
  }

  &__table-preview {
    position: absolute;
    top: 0;
    left: 100%;
    height: 100%;
    width: calc(100vw - 330%);
    z-index: 10;
  }
}
