.common-loader__backdrop {
  z-index: 100 !important;
}

.common-loader__animation {
  width: 120px;
  margin: auto;
}

.common-loader__backdrop svg {
  max-width: calc(120px * var(--scale-coefficient)) !important;
  max-height: calc(120px * var(--scale-coefficient)) !important;
}
