.column-view-options-dialog__title {
    font-family: Manrope;
    font-style: normal;
    font-weight: bold;
    font-size: calc(14px * var(--scale-coefficient));
    line-height: calc(20px * var(--scale-coefficient));
    color: var(--black);
    margin-bottom: calc(10px * var(--scale-coefficient));
}

.column-view-options-dialog__palette {
    width: calc(300px * var(--scale-coefficient));
}

.column-view-options-dialog__palette:first-child {
    margin-right: calc(15px * var(--scale-coefficient));
}

.column-view-options-dialog__buttons-container {
    display: flex;
    width: 100%;
    padding: calc(12px * var(--scale-coefficient)) calc(16px * var(--scale-coefficient));
    border-top: 1px solid var(--light-grey);
}

.column-view-options-dialog__color-setting {
    display: flex;
}

.column-view-options-dialog__block {
    margin-top: calc(15px * var(--scale-coefficient));
}

.column-view-options-dialog__block-input {
    width: calc(300px*var(--scale-coefficient));
}


.column-view-options-dialog__block:first-child {
    margin-top: 0;
}

.column-view-options-dialog__main {
    padding: calc(12px * var(--scale-coefficient)) calc(16px * var(--scale-coefficient));
    max-height: 70vh;
    overflow: auto;
}
