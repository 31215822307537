.select-fields-panel { 
  &__options-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
     padding: 0 calc(20px * var(--scale-coefficient));
  }
  &__options-section-left {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  &__options-section-select-all-text  {
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: calc(14px * var(--scale-coefficient));
    line-height: calc(14px * var(--scale-coefficient));
  }
  &__options-section-left-side-button {
    margin: 0 calc(5px * var(--scale-coefficient)) !important;
  }
  &__options-section-dotted-add {
    font-family: Roboto;
    font-style: normal;
    font-weight: 600;
    font-size: calc(14px * var(--scale-coefficient));
    line-height: calc(14px * var(--scale-coefficient));
    color: var(--primary-color);
    border-bottom: 1px dotted var(--primary-color);
  }
  &__options-section-dotted-delete {
    font-family: Roboto;
    font-style: normal;
    font-weight: 600;
    font-size: calc(14px * var(--scale-coefficient));
    line-height: calc(14px * var(--scale-coefficient));
    color: var(--dark-grey);
    border-bottom: 1px dotted var(--dark-grey);
  }
  &__options-section-svg-pic {
    margin-right: calc(5px * var(--scale-coefficient)) !important;
  }
}
