.card-settings {
  background: var(--white);
  height: calc(100vh - var(--panel-size));
  width: calc(730px * var(--scale-coefficient));
  display: flex;
  flex-direction: column;
}

.map-popover-dropdown__item {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: calc(14px * var(--scale-coefficient));
  line-height: calc(16px * var(--scale-coefficient));
  color: var(--black);
  padding: calc(10px * var(--scale-coefficient)) calc(10px * var(--scale-coefficient)) calc(10px * var(--scale-coefficient)) calc(15px * var(--scale-coefficient));
}

.map-popover-dropdown__item:hover {
  background-color: rgba(0, 0, 0, 0.08);
}

.map-popover-dropdown__item-text {
  margin-left: calc(-10px * var(--scale-coefficient));
}

.card-settings__list {
  overflow: auto;
}

.card-settings__header {
  height: var(--panel-size);
  padding: 0 var(--modal-left-right-padding);
  display: flex;
  align-items: center;
  position: relative;
  justify-content: space-between;
  border-bottom: 1px solid var(--dark-cyan);
}

.card-settings__content {
  padding: var(--modal-bottom-top-padding) var(--modal-left-right-padding);
  flex: 1;
  min-height: 0;
  display: flex;
  flex-direction: column;
}

.card-settings__additional-settings label {
  margin-right: calc(10px * var(--scale-coefficient));
}

.card-settings__title {
  font-family: Manrope;
  font-style: normal;
  font-weight: bold;
  font-size: calc(18px * var(--scale-coefficient));
}

.card-settings__descr {
  font-size: calc(14px * var(--scale-coefficient));
  line-height: 1;
  color: #444;
  margin: calc(16px * var(--scale-coefficient)) 0 calc(20px * var(--scale-coefficient));
}

.card-settings__close {
  width: calc(46px * var(--scale-coefficient));
  height: calc(46px * var(--scale-coefficient));
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 3px;
  outline: none;
  border: 1px solid var(--dark-cyan);
  transition: all 0.3s;
  background: var(--white);
}

.card-settings__buttons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: calc(10px * var(--scale-coefficient)) 0;
}

.card-settings__add-button-text {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: calc(14px * var(--scale-coefficient));
  line-height: calc(14px * var(--scale-coefficient));
  color: var(--primary-color);
  border-bottom: 1px dashed var(--primary-color);
  text-transform: none;
}

.card-settings__delete-button-text {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: calc(14px * var(--scale-coefficient));
  line-height: calc(14px * var(--scale-coefficient));
  color: var(--dark-grey);
  border-bottom: 1px dashed var(--dark-grey);
  text-transform: none;
}

.map-popover .SVGInline {
  vertical-align: top;
}

.card-settings__button > span {
  display: flex;
  align-items: center;
}

.card-settings__button > span > span {
  line-height: 1;
  margin-left: 0;
  vertical-align: top;
}

.card-settings__field-type {
  margin-right: calc(10px * var(--scale-coefficient));
  vertical-align: middle;
  display: flex;
}

.card-settings__field-type-svg {
  vertical-align: middle;
}

.card-settings__field-name {
  display: inline-block;
  vertical-align: middle;
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: calc(14px * var(--scale-coefficient));
  line-height: calc(14px * var(--scale-coefficient));
  color: var(--title-color);
}

.card-settings__field-type {
  margin-left: calc(-12px * var(--scale-coefficient));
}

.card-settings__bottom-panel {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: calc(18px * var(--scale-coefficient)) calc(20px * var(--scale-coefficient));
}
