.color-palette {
  padding: calc(10px * var(--scale-coefficient));
  border: 1px solid var(--dark-cyan);
  margin-bottom: calc(10px * var(--scale-coefficient));
  cursor: pointer;
  color: var(--title-color);
  border-radius: var(--base-border-radius);

  &:hover {
    background-color: #EDF1F8;
  }
}

.color-palette.active {
  background-color: #EDF1F8;
  cursor: initial;
  border: 1px solid var(--primary-color);
  box-shadow: var(--shadow-block);
}

.color-palette__item-container {
  display: flex;
  margin-top: calc(8px * var(--scale-coefficient));
}

.color-palette__item {
  width: calc(100% / 12);
  height: calc(15px * var(--scale-coefficient));
  border-radius: 0;

  &:first-child {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }

  &:last-child {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
  }
}

.color-palette__first-color {
  background-color: var(--white);
  padding: calc(10px * var(--scale-coefficient));
  border: 1px solid var(--dark-cyan);
  margin-top: calc(15px * var(--scale-coefficient));
  border-radius: 4px;
  border-bottom: 2px var(--primary-color) solid;
  cursor: pointer;
  display: flex;
}

.color-palette__first-color-item {
  width: calc(100% / 12);
  height: calc(25px * var(--scale-coefficient));
  border-radius: 4px;
  border: 1px solid var(--dark-cyan);
}

.color-palette__first-color-text {
  margin: calc(4px * var(--scale-coefficient)) 0 0 calc(12px * var(--scale-coefficient));
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  color: var(--dark-grey);
}

.color-palette__first-color-arrow {
  margin-left: auto;
}

.color-palette__first-color__dropdown {
  margin-left: auto;
  z-index: 15;
}

.color-palette__first-color__dropdown-outer-container {
  display: flex;
  flex-wrap: wrap;
  background: white;
  border: 1px solid var(--dark-cyan);
  border-radius: 4px;
}
.color-palette__first-color__dropdown-container {
  display: flex;
  flex-wrap: wrap;
  width: calc(190px * var(--scale-coefficient));
  background: white;
  border: 1px solid var(--dark-cyan);
  border-radius: 4px;
  margin: calc(10px * var(--scale-coefficient));
}

.color-palette__first-color__dropdown-item {
  width: calc(25px * var(--scale-coefficient));
  height: calc(25px * var(--scale-coefficient));
  flex: 1 0 10%;
  margin: calc(10px * var(--scale-coefficient));
  border-radius: 4px;
  border: 1px solid var(--dark-cyan);
  cursor: pointer;

  &:hover {
    border: 1px solid var(--white);
    box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.3), 0px 4px 10px rgba(0, 0, 0, 0.06), 0px 4px 10px rgba(58, 130, 228, 0.12);
  }
}

.color-palette__first-color__dropdown-item.active {
  border: 1px solid var(--white);
  cursor: initial;
  box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.3), 0px 4px 10px rgba(0, 0, 0, 0.06), 0px 4px 10px rgba(58, 130, 228, 0.12);
}

.color-palette__wrap_icon {
  width: calc(24px * var(--scale-coefficient));
  height: calc(24px * var(--scale-coefficient));
  text-align: center;
  margin: 0 calc(4px * var(--scale-coefficient));
  cursor: pointer;
}

.color-palette__wrap_icon svg {
  vertical-align: top;
}

.color-palette__button {
  display: flex;
  flex-direction: row;
}

.color-palette__button svg {
  vertical-align: top;
}

.color-palette__button span {
  margin-left: calc(8px * var(--scale-coefficient));
}

