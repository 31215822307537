.edit-form {
  width: calc(460px * var(--scale-coefficient));
  padding: calc(14px * var(--scale-coefficient)) calc(10px * var(--scale-coefficient));

  &__container {
    margin: calc(18px * var(--scale-coefficient)) 0;
  }

  &__row {
    margin-top: calc(14px * var(--scale-coefficient));
    display: flex;

    & > * {
      margin-right: calc(10px * var(--scale-coefficient));
    }
    & > *:last-child {
      margin-right: 0;
    }
  }

}
