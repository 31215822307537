.sql-script-panel {
  position: absolute;
  top: 0;
  left: 100%;
  z-index: 10;
  width: calc(454px * var(--scale-coefficient));
  height: 100%;
  padding: calc(16px * var(--scale-coefficient)) calc(20px * var(--scale-coefficient)) calc(18px * var(--scale-coefficient));
  display: flex;
  flex-direction: column;
  box-shadow: 0px 4px 10px rgba(58, 130, 228, 0.12),
  0px 4px 10px rgba(0, 0, 0, 0.06);
  background-color: var(--white);

  &__title {
    color: var(--black);
    font-family: 'Manrope', 'Arial', sans-serif;
    font-size: calc(14px * var(--scale-coefficient));
    font-weight: bold;
    line-height: calc(46px * var(--scale-coefficient));
    margin-bottom: calc(12px * var(--scale-coefficient));
  }
}
