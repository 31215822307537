.legend-donut {
  font-family: Roboto, arial, sans-serif;
  font-weight: 500;
  margin: 0 0 0 auto;
  padding: 0 10px 0 0;
  display: flex;
  flex-direction: column;
}

.legend-donut .visx-legend-item:first-child {
  margin-top: auto !important;
}

.legend-donut .visx-legend-item:last-child {
  margin-bottom: auto !important;
}

.legend-donut-label__content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-width: 250px;
  width: 100%;
}

.legend-donut-label__text {
  margin-left: 25px;
  color:#808080;
  font-size: 16px;
  line-height: 18.75px;
  flex: 1;
  min-width: 5.5em;
}

.legend-donut-label__values {
  margin-left: 25px;
  color: var(--black);
  font-size: 16px;
  line-height: 18.75px;
}

.legend-donut-label__percents {
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;
  color: var(--black);
  text-align: center;
  margin: 0 5px 0 15px;
  display: flex;
  justify-content: center;
}

.legend-donut-label__percent-value {
  min-width: 4.5em;
  display: flex;
  justify-content: flex-end;
  margin-left: calc(8px * var(--scale-coefficient));
}

.legend-donut-label__container {
  display: flex;
  margin: 0 5px 0 5px;
}

.legend-donut-label__multi-value {
  margin-left: 5px;
  min-width: 5.5em;
}

.legend-donut-label__percent-multi-value {
  min-width: 4.5em;
  margin-left: 10px;
  display: flex;
}

