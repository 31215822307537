.widget-table-cell {
    position: relative;
    width: 100%;
    height: 100%;
    align-items: center;
    display: grid;
}

.widget-table-cell__progress {
    position: absolute;
    top: calc(6px * var(--scale-coefficient));
    bottom: calc(5px * var(--scale-coefficient));
}

.widget-table-cell__value-container {
    position: relative;
    margin: calc(8px * var(--scale-coefficient));
    min-width: 0;
}

.widget-table-cell__value {
    font-size: calc(14px * var(--scale-coefficient));
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    word-break: break-word;
}

.widget-table-cell__head-value, .widget-table-cell__head-value-container > div {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    word-break: break-word;
    white-space: normal;
    font-family: Roboto, sans-serif;
    font-weight: 500;
}

.widget-table-cell__value_two, .widget-table-cell__head-value_two, .widget-table-cell__head-value-container.widget-table-cell__head-value_two > div {
    -webkit-line-clamp: 2;
}

.widget-table-cell__value_three, .widget-table-cell__head-value_three, .widget-table-cell__head-value-container.widget-table-cell__head-value_three > div {
    -webkit-line-clamp: 3;
}
