.source-node-model {
    display: flex;
    flex-direction: column;
    width: calc(286px * var(--scale-coefficient));
    padding: 0;
    background-color: var(--white);
    border: 1px solid var(--dark-cyan);
    border-left: 3px solid var(--primary-color);
    border-radius: 5px;
    box-shadow: 0px 4px 10px rgba(58, 130, 228, 0.12),
    0px 4px 10px rgba(0, 0, 0, 0.06);
    white-space: nowrap;
    text-overflow: ellipsis;
    max-height: calc(1000px * var(--scale-coefficient));
    overflow: auto;

    &__text-container {
        padding: calc(8px * var(--scale-coefficient)) calc(12px * var(--scale-coefficient));
        background-color: var(--primary-color);
        color: var(--white);
    }

    &__text {
        font-family: Roboto, Arial, sans-serif;
        font-weight: 500;
        font-size: calc(16px * var(--scale-coefficient));
        text-align: center;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    &__icon-box {
        flex-shrink: 0;
        margin-left: auto;
    }

    &__list {
        display: flex;
        flex-direction: column;
        padding: calc(5px * var(--scale-coefficient)) calc(12px * var(--scale-coefficient));
    }

    &__list-item-container {
        display: flex;
        align-items: center;
        height: calc(24px * var(--scale-coefficient));
    }

    &__list-item {
        text-overflow: ellipsis;
        max-width: calc(220px * var(--scale-coefficient));
        overflow: hidden;
        margin-left: calc(7px * var(--scale-coefficient));
        height: calc(18px * var(--scale-coefficient));
         font-size: calc(16px * var(--scale-coefficient));
         line-height: calc(16px * var(--scale-coefficient));
        display: flex;
        align-items: center;
    }

    &__list-item-icon {
        margin-left: auto;
    }

    &__list-item-key {
        font-weight: 600;
        max-width: calc(190px * var(--scale-coefficient));
    }
}

.react-flow__edge.selected .react-flow__edge-path {
    stroke: var(--title-color) !important;
}
