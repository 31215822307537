.widget-table__container {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
}

.widget-table__pagination-container {
    padding: calc(12px * var(--scale-coefficient)) calc(24px * var(--scale-coefficient));
}

.widget-table__full-height {
    flex-grow: 1;
    overflow-x: auto;
    overflow-y: hidden;
    box-sizing: border-box;
    background-color: var(--white);
}

.widget-table__resizer {
    display: inline-block;
    background: #3A82E49c;
    width: 3px;
    height: 100%;
    position: absolute;
    right: 0;
    top: 0;
    transform: translateX(50%);
    z-index: 1;
    cursor: col-resize;
}

.widget-table__table {
    max-width: 100%;
    height: 100%;
}

.widget-table__table-body {
    max-width: 100%;
    height: 100%;
}

.widget-table__table-head {
    z-index: 1;
    top: 0;
    padding: 0;
}

.widget-table__table-header-cell-content {
    box-sizing: border-box;
    height: 100%;
    padding-left: calc(4px * var(--scale-coefficient));
}

.widget-table__resizing {
    background: var(--primary-color);
}

.widget-table__header-cell {
    color: var(--grey);
    font-size: calc(14px * var(--scale-coefficient));
    min-height: calc(44px * var(--scale-coefficient));
    padding: 0;
}

.widget-table__resizing-cell {
    background: #eaf0fa;
}

.widget-table__body-cell {
    background: transparent;
}

.widget-table__body-cell-combined > div {
    background: #ffffff !important;
}

.widget-table__body-cell-combined {
    border-top: none !important;
}

.widget-table__body-cell-combined-start > div {
    background: #ffffff !important;
}

.widget-table__body-cell-last-row {
    border-bottom: 1px solid var(--dark-cyan) !important;
}

.widget-table__body-cell-total {
    margin-bottom: 6px;
}

.widget-table__cell-bordered:last-child {
    border-right: none !important;
}

.widget-table__cell-bordered {
    border-right: 1px solid var(--dark-cyan) !important;
    border-left: none !important;
    border-bottom: 1px solid transparent;
}

.widget-table__cell {
    border-bottom: 1px solid transparent;
    border-top: 1px solid var(--dark-cyan);
    padding: 0;
}

.widget-table__row {
    min-width: 100%;
}

.widget-table__body-row {
    will-change: background;
    display: flex;
}

.widget-table__body-row:hover {
    /* transition: background .4s;
    background: rgba(34, 109, 201, 0.04); */
}

.widget-table__selected-cell {
    border-left: 1px solid var(--dark-cyan);
    border-right: 1px solid var(--dark-cyan);
}

.widget-table__center-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}

.widget-table__filter-row .widget-table-cell {
    background-color: #94baee !important;
}

.widget-table__header-cointainer-value_two {
    height: calc(60px * var(--scale-coefficient));
}

.widget-table__header-cointainer-value_three {
    height: calc(70px * var(--scale-coefficient));
}
