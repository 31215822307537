:root {
    --scale-coefficient: 0.68;
    --zoom: 68%;

    // measures
    --panel-size: calc(62px * var(--scale-coefficient));
    --panel-size_small: calc(42px * var(--scale-coefficient));

    --base-border-radius: calc(8px * var(--scale-coefficient));

    --modal-bottom-top-padding: calc(20px * var(--scale-coefficient));
    --modal-left-right-padding: calc(20px * var(--scale-coefficient));

    --modal-left-right-padding_medium: calc(16px * var(--scale-coefficient));
    --modal-bottom-top-padding_medium: calc(16px * var(--scale-coefficient));

    // shadows
    --shadow-block: 0px 4px 10px rgba(0, 0, 0, 0.06), 0px 4px 10px rgba(58, 130, 228, 0.12);
    --shadow-button-filled-normal: 0px 4px 10px rgba(0, 0, 0, 0.06), 0px 4px 10px rgba(58, 130, 228, 0.12);
    --shadow-button-filled-hover: 0px 3px 8px rgba(0, 0, 0, 0.12), 0px 4px 10px rgba(0, 0, 0, 0.12), 0px 4px 10px rgba(58, 130, 228, 0.2);
    --shadow-button-outline-hover: 0px 4px 10px rgba(0, 0, 0, 0.08), 0px 4px 10px rgba(58, 130, 228, 0.06);
    --shadow-drag-and-drop: 0px 13px 24px rgba(75, 88, 129, 0.2);
    --shadow-tooltip-hint: 0px 2px 3px rgba(0, 0, 0, 0.06), 0px 5px 8px rgba(58, 130, 228, 0.08);
    --shadow-switcher: 0px 0px 2px rgba(0, 0, 0, 0.12), 0px 1px 2px rgba(0, 0, 0, 0.16);
    --shadow-inner-table: inset 0px 4px 4px rgba(24, 54, 100, 0.08);
    --shadow-inner-sidebar: inset 0px 2px 4px rgba(0, 0, 0, 0.15);

    // colors

    // blue
    --blue-normal: #3A82E4;
    --blue-hover: #4A94F8;
    --blue-opacity: #ebf3fc;
    --blue-active: #226DC9;
    --blue-title: #183664;
    // red
    --red-normal: #F44545;
    --red-opacity: #feecec;
    --red-hover: #F75E5E;
    --red-active: #B41F1F;
    // black
    --black: #444444;
    // white
    --white: #FFFFFF;
    // grey
    --dark-grey: #929BAC;
    --grey: #808080;
    --medium-grey: #C4C4C4;
    --medium-grey-opacity: #f6f6f6;
    --light-grey: #EBEBEB;
    // cyan
    --dark-cyan: #C5D9E8;
    --medium-cyan: #DAE7ED;
    --light-cyan: #EDF1F8;
    // yellow
    --dark-yellow: #F5A834;
    --light-yellow: #fef2e1;
    // green
    --dark-green: #0cc009;
    --light-green: #dbf6da;

    // default items colors
    --default-primary-color: var(--blue-normal);
    --default-primary-hover-color: var(--blue-hover);
    --default-backdrop-color: #f5f9fe;
    --default-success-color: var(--dark-green);
    --default-title-color: var(--blue-title);
    --default-backdrop-item-color: #e2ecfd;
}

@media (min-width: 1600px) {
    :root {
        --scale-coefficient: 1;
        --zoom: 100%;
    }
}
