.project-dashboards {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

@media screen and (max-width: 800px) {
  .project-dashboards {
    height: 100dvh;
  }
}