.legend-v-donut-container {
    display: flex;
    flex-direction: row;
    margin-left: 16px;
    color: #808080;
    font-size: 14px;
    padding: 8px 0;
    flex-wrap: wrap;
}

.legend-v-donut-label__text_multiply {
    color: #808080;
    font-family: Roboto, sans-serif;
    font-size: 14px;
    margin-left: 5px;
}

.legend-v-donut-label__text {
    color: #808080;
    font-family: Roboto, sans-serif;
    font-size: 14px;
    margin-right: 4px;
}

.legend-v-donut-label__value {
    font-family: Roboto, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    text-align: center;
    margin-left: 8px;
}