.operation-type-item {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: calc(14px * var(--scale-coefficient));
  line-height: calc(16px * var(--scale-coefficient));
  color: var(--black);

  &__list {
    display: none !important;
    position: absolute;
    top: 0;
    left: 100%;
  }

  &__inner-item {
    &:hover {
      color: var(--primary-color);
      background-color: rgba(0, 0, 0, 0.04);
      cursor: pointer;
    }
    background: var(--white);
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: calc(14px * var(--scale-coefficient));
    line-height: calc(16px * var(--scale-coefficient));
    color: var(--black);
  }

  &__inner-menu {
    background: var(--white);
    border: 1px solid #DAE7ED;
    box-sizing: border-box;
    box-shadow: 0px 4px 10px rgba(58, 130, 228, 0.12), 0px 4px 10px rgba(0, 0, 0, 0.06);
    border-radius: 4px;
     width: calc(400px * var(--scale-coefficient));
     max-width: calc(250% - 32px);
     max-height: calc(350px * var(--s54cale-coefficient));
     overflow-y: auto;
     overflow-x: hidden;
     word-break: break-all;
  }
}

.operation-type-item.active > .operation-type-item__list {
  display: block !important;
  position: absolute;
  top: 0;
  left: 100%;
}

.operation-type-item.active > .list-bottom {
  top: auto;
  bottom: 0;
}

.operation-type-item > div > div {
  margin-top: 0;
  margin-bottom: 0;
}