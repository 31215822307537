.loader-page {
  display: flex;
  height: 100%;
  background-color: var(--backdrop-color);
}

.loader-page:before {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: -webkit-repeating-radial-gradient(center center, var(--backdrop-item-color), var(--backdrop-item-color) 1px, transparent 2px, transparent 100%);
  -webkit-background-size: calc(24px * var(--scale-coefficient)) calc(24px * var(--scale-coefficient));
  -moz-background-size: calc(24px * var(--scale-coefficient)) calc(24px * var(--scale-coefficient));
  background-size: calc(24px * var(--scale-coefficient)) calc(24px * var(--scale-coefficient));
}

.loader-page__source-list {
  position: absolute;
  top: 0;
  left: 303px;
  height: 100%;
  z-index: 10;
}

.loader-page__map {
  flex: 1 1 auto;
}

.loader-page__files-list {
  position: absolute;
  top: 0;
  left: 303px;
  height: 100%;
  z-index: 10;
}

.loader-page__source-form {
  position: absolute;
  top: 0;
  left: 606px;
  height: 100%;
  z-index: 10;
}

.loader-page__table-preview {
  position: absolute;
  top: 0;
  left: 606px;
  height: 100%;
  width: calc(95% - 606px);
  z-index: 10;
}

.loader-page__backdrop {
  z-index: 50 !important;
}
