.widget-overlay-graph {
  display: flex;
  flex-grow: 1;
  position: relative;
  overflow-y: hidden;
}

.widget-overlay-graph__brush--tip {
  font: 14px "Fira Sans", sans-serif;
  fill: #ebebeb;
}

/* переопределение глобальных стилей библиотеки visx, котоыре не удалось задать через интерфейс библиотеки */
.widget-overlay-graph .visx-brush-overlay {
  fill: #ebebeb;
  rx: 4px;
}

.widget-overlay-graph text {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently */
}

.widget-overlay-graph .visx-axis-line {
  opacity: 0;
}

.widget-overlay-graph .visx-axis-tick .visx-line {
  opacity: 0;
}
