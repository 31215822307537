
.history-row__sign:before {
  content: '';
  position: absolute;
  top: 50%;
  left: 0px;
  width: 2px;
  height: calc(30px * var(--scale-coefficient));
  background-color: currentColor;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  transform: translate(0, -50%);
}

.history-row__sign__default:before {
  background-color: #000;
}

.history-row__sign__error:before {
  background-color: var(--red-normal);
}

.history-row__sign__done:before {
  background-color: var(--success-color);
}

.history-row__step-title {
  font-weight: bold;
}

.history-row__step-status {
  padding-left: calc(30px * var(--scale-coefficient));
}

.history-row__step-status-icon {
  position: absolute;
  top: 50%;
  left: calc(8px * var(--scale-coefficient));
  transform: translate(-50%, -50%);
}

.history-row__alert-box {
  display: flex;
  align-items: center;
}

.history-row__alert-text {
  width: calc(720px * var(--scale-coefficient));
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.history-row__alert-text-full {
  white-space: initial;
  overflow: visible;
}

.history-row__alert-text-more {
  border: none;
  background-color: transparent;
  color: var(--primary-color);
  text-decoration-style: dotted;
  text-decoration-color: inherit;
  text-decoration-line: underline;
  cursor: pointer;
}
