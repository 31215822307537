.connection-form {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  width: calc(303px * var(--scale-coefficient));
  height: 100%;
  padding: calc(16px * var(--scale-coefficient)) calc(20px * var(--scale-coefficient)) calc(18px * var(--scale-coefficient));
  background-color: var(--white);
  border-right: 1px solid #DAE7ED;
  overflow: auto;
  
  &__title {
    margin-bottom: calc(12px * var(--scale-coefficient));
    color: var(--black);
    font-family: 'Manrope', 'Arial', sans-serif;
    font-size: calc(14px * var(--scale-coefficient));
    font-weight: bold;
    line-height: calc(46px * var(--scale-coefficient));
    grid-column: 1 / span 3;
  }

  &__error-text {
    color: palevioletred;
    display: block;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  
  &__form {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
  }
  
  &__fields {
    display: grid;
    grid-template-columns: calc(77px * var(--scale-coefficient)) 1fr;
    grid-column-gap: calc(16px * var(--scale-coefficient));
    grid-row-gap: calc(12px * var(--scale-coefficient));
  }
  
  &__field {
    grid-column: span 4;  
  }
  
  &__title {
    color: #444;
    
    &_mt {
      margin-top: calc(10px * var(--scale-coefficient));
    }
  }
  
  &__buttons {
    display: flex;
    justify-content: space-between;
    margin-top: calc(20px * var(--scale-coefficient));
  }
  
  &__no-data {
    margin-top: calc(26px * var(--scale-coefficient));
  }
  
  &__error {
    grid-column: 1 / span 3;    
    &__title {
      font-weight: bold;
    }

    .MuiAlert-message {
      width: calc(100% - 30px * var(--scale-coefficient));
    }
  }
}
