.calculated-indicator-panel > div {
  display: flex;
}

.calculated-indicator-panel__side {
  width: 320px;
  border-right: 1px solid var(--light-grey);
}

.calculated-indicator-panel__side_top {
  border-bottom: 1px solid var(--light-grey);
  padding: calc(12px * var(--scale-coefficient)) calc(16px * var(--scale-coefficient));
}

.calculated-indicator-panel__side_bottom {
  padding: 0 calc(10px * var(--scale-coefficient)) 0 calc(16px * var(--scale-coefficient));
  margin-right: calc(5px * var(--scale-coefficient));
  margin-top: calc(12px * var(--scale-coefficient));
  overflow-y: scroll;
  height: 300px;
}

.calculated-indicator-panel__main {
  padding: calc(12px * var(--scale-coefficient)) calc(16px * var(--scale-coefficient));
  width: 572px
}

.calculated-indicator-panel__main__textarea-section {
  border-radius: 4px;
  height: calc(100% - 175px);
  border: 1px solid var(--dark-cyan);
  margin: calc(20px * var(--scale-coefficient)) 0 calc(10px * var(--scale-coefficient));
  padding-top: 6px;
}

.calculated-indicator-panel__main__textarea-section__main {
  display: flex;
  flex-direction: row;
  height: 100%;
}

.calculated-indicator-panel__main__textarea-section__formulas-list {
  width: auto;
  margin-left: calc(12px * var(--scale-coefficient));
  margin-right: 2px;
}

.calculated-indicator-panel__main__example-section {
  margin: calc(20px * var(--scale-coefficient)) 0 0;
}

.calculated-indicator-panel__main__example-section__icon {
  padding: calc(16px * var(--scale-coefficient));
}

.calculated-indicator-panel__main__example-section__text {
  padding-top: 16px;
  padding-bottom: 14px;
  display: flex;
  flex-direction: column;
}

.calculated-indicator-panel__main__example-section__text-title {
  font-family: Roboto;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 14px;
  margin-bottom: 9px;
}

.calculated-indicator-panel__popover {
  overflow-x: initial;
  overflow-y: initial;
}

.calculated-indicator-panel__buttons {
  padding: 12px 16px;
  border-top: 1px solid var(--light-grey);
}

.calculated-panel__textarea {
  width: 100%;
  height: 100%;
}

.modal__title {
  width: 262px;
  margin: 0 auto;
  margin-top: 6px;
  margin-bottom: 16px;
  font-family: 'Manrope', 'Arial', sans-serif;
  font-weight: bold;
  font-size: 18px;
  line-height: 18px;
  word-break: break-word;
}

.modal__controls {
  margin-top: 20px;
}

.alert {
  width: 100%;
  max-height: 200px;
  overflow-y: auto;
  margin: 20px auto;
  text-align: left;
}
