.new-file-panel {
  width: calc(303px * var(--scale-coefficient));
  padding: calc(15px * var(--scale-coefficient)) calc(20px * var(--scale-coefficient)) calc(20px * var(--scale-coefficient));
  background-color: var(--white);
  display: flex;
  flex-direction: column;
  
  &__title {
    color: var(--black);
  }
  
  &__no-data {
    margin-top: calc(26px * var(--scale-coefficient));
  }
  
  &__error {
    color: #f00;
    word-wrap: break-word;
    
    &_mt {
      margin-top: calc(26px * var(--scale-coefficient));
    }
  }

   &__table-preview {
    position: absolute;
    top: 0;
    left: 100%;
    height: 100%;
    width: calc(100vw - 330%);
    z-index: 10;
  }
}
