.widget-bubble-chart {
    display: flex;
    position: relative;
    flex-grow: 1;
    overflow-y: hidden;
    overflow-x: hidden;
}

.widget-bubble-chart div{
    position: absolute;
}

.widget-bubble-chart .relative{
    position: relative;
}

.widget-bubble-chart__svg {
    width: 100%;
    flex-grow: 1;
    overflow-x: hidden;
    overflow-y: hidden;
}

.widget-bubble-chart__value {
    width: 0;
    height: 0;
}

.widget-bubble-chart__value-labels {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.widget-bubble-chart__value-circle {
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    border-radius: 50%;
}

.widget-bubble-chart__axis-bottom-label {
    width: 100%;
}
