.dashboards-link {
  text-decoration: none;
  display: inline-block;
  margin-left: 10px;
  vertical-align: middle;
  color: var(--white);
}

.project-card__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.project-card__icons {
  display: flex;
}

.project-card__icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: calc(34px * var(--scale-coefficient));
  height: calc(34px * var(--scale-coefficient));
  border-radius: 50%;
  background-color: var(--white);
}

.project-card__icon svg {
  width: calc(20px * var(--scale-coefficient)) !important;
  height: calc(20px * var(--scale-coefficient)) !important;
}

.project-card__icon:not(:first-child) {
  margin-left: -7px;
}

.project-card {
  border-top: 4px solid var(--dark-grey);
  width: calc(284px * var(--scale-coefficient));
  height: calc(204px * var(--scale-coefficient));
  border-radius: var(--base-border-radius);
  background-image: url('./images/card-bg-blue.svg');
  position: relative;
  box-shadow: none;
}

@media all and (max-width: 500px) {
  .project-card {
    border-top: 1px solid var(--dark-grey);
    width: 100%;
    height: calc(204px * var(--scale-coefficient));
    border-radius: 4px 4px 0 0;
    background-image: url('./images/card-bg-blue.svg');
    position: relative;
    background-size: cover;
    background-repeat: no-repeat;
  }
}

.project-card__project-link {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 5;
  text-decoration: none;
}

.project-card.published {
  border-top-color: #f5a834;
}

.project-card.unpublished {
  border-top-color: var(--dark-grey);
}

.project-card svg {
  vertical-align: top;
}

.project-card__status-icon {
  z-index: 30;
}

.project-card__status {
  margin-right: 15px;
}

.project-card-update__time-date {
  margin: 0 13px;
  text-align: right;
  white-space: nowrap;
}

/* styles for personal project */

.personal {
  background-image: url('./images/card-bg-white.svg');
}

.personal .project-card__title {
  color: var(--title-color);
}

.personal .project-card__status-text {
  color: var(--black);
}

.personal .project-card__title {
  color: var(--title-color);
}

.personal .project-card__more-button {
  color: #444;
}

.personal .project-card__status-text {
  color: #444;
}

.personal .project-card__count-text {
  color: #444;
}

.personal .project-card__status-text {
  color: var(--dark-grey);
}

.personal .project-card__icon {
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.06), 0px 4px 10px rgba(58, 130, 228, 0.12);
}

.personal .dashboards-link {
  color: var(--title-color)
}

.personal .project-card__count-text {
  color: var(--title-color);
}

.project-card__wrapper {
  padding: calc(20px * var(--scale-coefficient)) calc(14px * var(--scale-coefficient)) calc(14px * var(--scale-coefficient));
  background-position: 100% 100%;
  background-repeat: no-repeat;
}

.project-card__status-text {
  color: var(--white);
}

.project-card__descr {
  margin: calc(18px * var(--scale-coefficient)) 0;
}

.project-card__subtitle {
  line-height: 1;
  color: var(--dark-grey);
}

.project-card__title {
  margin-top: 6px;
  font-family: 'Manrope';
  line-height: 16px;
  text-transform: uppercase;
  color: var(--white);
  width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  font-weight: 700;
}

.project-card__fullnes {
  display: flex;
}

.project-card__count {
  display: flex;
  margin-right: calc(25px * var(--scale-coefficient));
}

.project-card__icon-container {
  z-index: 6;
  margin-right: calc(15px * var(--scale-coefficient));
  cursor: default;
}

.project-card__count-icon {
  width: calc(15px * var(--scale-coefficient));
  height: calc(15px * var(--scale-coefficient));
}

.project-card__count-text {
  color: var(--white);
  font-weight: bold;
  font-size: calc(14px * var(--scale-coefficient));
}

.project-card__buttons {
  justify-content: space-between;
  position: relative;
  z-index: 20;
  padding-left: 0;
}

.project-card__more-button {
  color: var(--dark-grey);
  box-shadow: none;
}

