.loader-table {
  background: var(--white);
  margin: calc(20px * var(--scale-coefficient));
}

.loader-table .MuiTableCell-body {
  color: var(--title-color) !important;
}

.loader-table__table {
  border: 1px solid var(--dark-cyan);
  border-collapse: separate;
  border-radius: 8px;
}

.loader-table__head {
  background: #EDF1F8;
  height: calc(48px * var(--scale-coefficient));
}

.loader-table__head th:first-child, .loader-table__head th:first-child + th {
  border-top-left-radius: 8px;
}

.loader-table__head th:last-child {
  border-top-right-radius: 8px;
}

.loader-table th:last-child, .loader-table td:last-child {
  max-width: calc(220px * var(--scale-coefficient));
}

.loader-table__cell {
  padding: calc(8px * var(--scale-coefficient)) calc(28px * var(--scale-coefficient));
  border-bottom: 1px solid var(--dark-cyan);
}

.loader-table__cell:not(:first-child) {
  width: calc(100%/8);
}

.loader-table__link {
  text-decoration: none;
}

.loader-table__row:last-child .loader-table__cell {
  border-bottom: none;
}

.loader-table__cell > div {
  display: flex;
  align-items: center;
}

.loader-table__row {
  color: inherit;
}

.loader-table__cell-value {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: calc(14px * var(--scale-coefficient));
  line-height: calc(18px * var(--scale-coefficient));
}

.loader-table__cell_head .loader-table__cell-value {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: calc(14px * var(--scale-coefficient));
  line-height: calc(18px * var(--scale-coefficient));
  color: inherit;
}

.loader-table__cell_head {
  padding-top: 0;
  padding-bottom: 0;
}

.loader-table__cell_head:first-child {
  padding: 0;
}

.loader-table__cell-sort {
  margin-left: calc(8px * var(--scale-coefficient));
}

.loader-table__cell-icon {
  margin-right: calc(8px * var(--scale-coefficient));
  display: flex;
  align-items: center;
}

.loader-table__cell_settings > div > * {
  margin-right: calc(16px * var(--scale-coefficient));
}

.loader-table__cell_settings > div > *:last-child {
  margin-right: 0;
}

.loader-table__row:last-child {
  border-bottom: none;
}

.loader-table__row:before {
  content: '';
  position: absolute;
  top: 50%;
  left: 0;
  width: 2px;
  height: calc(30px * var(--scale-coefficient));
  background-color: var(--dark-grey);
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  transform: translate(0, -50%);
}

.loader-table__row_published:before {
  background-color: #f5a834;
}

.loader-table__cell_first .loader-table__cell-value {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: calc(14px * var(--scale-coefficient));
  line-height: calc(18px * var(--scale-coefficient));
}

.loader-table__link {
  color: inherit;
  text-decoration: inherit;
}
