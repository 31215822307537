.axis-condition__container {
  background: #edf1f8;
  padding: calc(20px * var(--scale-coefficient));
  margin: calc(12px * var(--scale-coefficient)) calc(16px * var(--scale-coefficient));
  border-radius: var(--base-border-radius);
}

.axis-condition__container > div:not(:last-child), .axis-condition__complex-formula > div:not(:last-child) {
  margin-bottom: calc(20px * var(--scale-coefficient));
}

.axis-condition__input--middle {
  flex-basis: 48%;
}

.axis-condition__input--small {
  flex-basis: 24%;
}

.axis-condition__formula {
  font-family: Manrope, sans-serif;
  font-weight: 700;
  font-size: calc(14px * var(--scale-coefficient));
  line-height: calc(18px * var(--scale-coefficient));
  color: var(--black);
  max-width: 400px;
  text-overflow: ellipsis;
  overflow: hidden;
}

.axis-condition__textarea {
  width: 100%;
  resize: none;
  outline: none;
  border: 1px solid var(--dark-cyan);
  border-radius: 4px;
  padding: calc(10px * var(--scale-coefficient));
}

.axis-condition-function {
  background-color: var(--white);
  width: 100%;
  border-radius: 4px;
  border: 1px solid var(--dark-cyan);
}

.axis-condition-function__title {
  min-height: calc(40px * var(--scale-coefficient));
  color: var(--black);
  display: flex;
  align-items: center;
  padding: calc(10px * var(--scale-coefficient));
}

.axis-condition-function__description-group {
  display: flex;
  margin-right: calc(20px * var(--scale-coefficient));
}

.axis-condition-function__description {
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: calc(14px * var(--scale-coefficient));
  line-height: calc(18px * var(--scale-coefficient));
  color: var(--dark-grey);
  margin-right: calc(10px * var(--scale-coefficient));
  display: flex;
  align-items: center;
}

.axis-condition-function__formulas-list svg {
  width: calc(24px * var(--scale-coefficient)) !important;
  height: calc(24px * var(--scale-coefficient)) !important;
}

.axis-condition-function__main {
  margin-right: auto;
  font-weight: 500;
  line-height: calc(22px * var(--scale-coefficient));
}

.axis-condition-function__textarea {
  padding: calc(10px * var(--scale-coefficient));
  width: 100%;
  border: none;
  background-color: transparent;
  resize: none;
  outline: none;
  cursor: default;
}

.axis-condition__popover {
  overflow-x: initial;
  overflow-y: initial;
}

.axis-condition-example {
  font-family: Roboto, sans-serif;
  border: 1px solid var(--dark-cyan);
  border-radius: var(--base-border-radius);
  height: auto;
  overflow-x: auto;
  display: flex;
  flex-direction: row;
  background: #f9fbfd;
}

.axis-condition-example__icon {
  padding: calc(16px * var(--scale-coefficient));
}

.axis-condition-example__text {
  padding-top: calc(20px * var(--scale-coefficient));
  padding-bottom: calc(14px * var(--scale-coefficient));
  display: flex;
  flex-direction: column;
}
.axis-condition-example__title {
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: calc(14px * var(--scale-coefficient));
  line-height: calc(14px * var(--scale-coefficient));
  margin-bottom: calc(9px * var(--scale-coefficient));
}

.axis-condition__checkbox-container {
  margin-top: calc(20px * var(--scale-coefficient));
}
