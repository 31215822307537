.operation-sub-type-section {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  padding: calc(20px * var(--scale-coefficient));

  &__arrow svg {
    width: calc(150px * var(--scale-coefficient)) !important;
    height: calc(24px * var(--scale-coefficient)) !important;
     max-width: none !important;
     max-height: none !important;
  }
  &__left-part {
    width: 35%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }
  &__right-part {
    width: 35%;
    padding-top: calc(7px * var(--scale-coefficient));
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  &__left-part-icon {
    padding-top: calc(14px * var(--scale-coefficient));
  }
  &__right-part-icon {
    transform: scale(-1, 1);
    padding-top: calc(8px * var(--scale-coefficient));
  }
  &__middle-part {
    margin-left: calc(12px * var(--scale-coefficient));
    margin-right: calc(12px * var(--scale-coefficient));
  }
  &__operation-sub-type-field {
    width: 23%;
    text-align: center;
    margin: 0;
  }
  &__operation-sub-type-input {
    border-radius: 40px !important;
    color: var(--title-color) !important;
    border: 1px solid var(--dark-cyan);
    box-shadow: 0px 4px 10px rgba(58, 130, 228, 0.12), 0px 4px 10px rgba(0, 0, 0, 0.06);
    height: calc(44px * var(--scale-coefficient)) !important;
    min-width: calc(198px * var(--scale-coefficient)) !important;
    font-family: Roboto;
    font-style: normal !important;
    font-weight: normal !important;
    font-size: calc(14px * var(--scale-coefficient)) !important;
    line-height: calc(16px * var(--scale-coefficient)) !important;
    text-align: center !important;

    .MuiSelect-icon {
      top: calc(50% - 12px * var(--scale-coefficient));
    }
  }
  &__info-block {
    text-align: center;
  }
  &__tbl-name {
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: calc(16px * var(--scale-coefficient));
    line-height: calc(16px * var(--scale-coefficient));
    text-align: center;
    color: var(--black);
    overflow: hidden;
    text-overflow: ellipsis;
    width: calc(250px * var(--scale-coefficient));
  }
  &__support-info {
    margin-top: calc(5px * var(--scale-coefficient));
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &__fields-ammount {
    vertical-align: middle;
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: calc(14px * var(--scale-coefficient));
    line-height: calc(14px * var(--scale-coefficient));
    color: var(--black);
    margin-right: calc(21px * var(--scale-coefficient));

  }
  &__new {
     color: var(--dark-grey);
     font-style: normal;
     font-weight: normal;
     font-size: calc(14px * var(--scale-coefficient));
     line-height: calc(34px * var(--scale-coefficient));
  }
  &__add-all-button {
    margin: 0 calc(5px * var(--scale-coefficient)) !important;
  }
  &__dotted {
    font-family: Roboto;
    font-style: normal;
    font-weight: 600;
    font-size: calc(14px * var(--scale-coefficient));
    line-height: calc(14px * var(--scale-coefficient));
    color: var(--primary-color);
    border-bottom: 1px dotted var(--primary-color);
  }
  &__svg-pic {
    margin-right: calc(5px * var(--scale-coefficient)) !important;
  }

  &__operation-sub-type-svg-pic {
    padding-bottom: calc(3px * var(--scale-coefficient));
    margin-right: calc(5px * var(--scale-coefficient)) !important;
  }

  &__white-bg {
    background: var(--white) !important;
  }
}

.operation-sub-type-section__middle-part {
  .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: var(--primary-color);
  }
}