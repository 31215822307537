.radar-axis__block {
    background: rgba(255, 255, 255, 0.5);
    border-radius: 20px;
    padding: 5px 10px;
    width: fit-content;
    margin: 0 auto;
}

.radar-axis__value {
    color: var(--grey);
    font-size: 14px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    line-height: 14px;
}
