.bound-type-switcher {
    padding: 0 calc(20px * var(--scale-coefficient));
}

.bound-type-switcher__type-filters-title {
    margin: calc(25px * var(--scale-coefficient)) 0 calc(16px * var(--scale-coefficient)) 0;
    font-family: Manrope;
    font-style: normal;
    font-weight: bold;
    font-size: calc(16px * var(--scale-coefficient));
    line-height: calc(18px * var(--scale-coefficient));
    color: var(--black);
}


.bound-type-switcher__tabs {
    display: flex;
}


.bound-type-switcher__tab {
    font-size: calc(14px * var(--scale-coefficient));
    cursor: pointer;
    flex-basis: 50%;
    padding: calc(15px * var(--scale-coefficient)) 0;
    background: var(--white);
    border: 1px solid #dae7ed;
    box-sizing: border-box;
    border-radius: 3px 0 0 3px;
    color: var(--dark-grey);
    outline: none;
    border-right: none;
}

.bound-type-switcher__tab:last-child {
    border-radius: 0 3px 3px 0;
    border-left: none;
    border-right: 1px solid #dae7ed;
}

.bound-type-switcher__tab--active {
    background: var(--primary-color);
    border: 1px solid #dae7ed;
    color: var(--white);
}

.bound-type-switcher__radio {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.bound-type-switcher__radio--label {
    font-size: calc(14px * var(--scale-coefficient));
}
