.loader-history {
  width: calc(900px * var(--scale-coefficient));
}

.loader-history__title-panel {
  display: flex;
  align-items: center;
  padding: calc(15px * var(--scale-coefficient)) calc(20px * var(--scale-coefficient));
  border-bottom: 1px solid var(--light-grey);
}

.loader-history__title {
  font-family: Manrope;
  font-style: normal;
  font-weight: 700;
  font-size: calc(18px * var(--scale-coefficient));
  line-height: calc(22px * var(--scale-coefficient));
  margin-right: auto;
  color: #444;
}

.loader-history__filter {
  margin: calc(20px * var(--scale-coefficient));
  display: flex;
  align-items: center;
}

.loader-history__filter > *:first-child {
  margin-right: calc(16px * var(--scale-coefficient));
}

.loader-history__filter-controls {
  margin-left: auto;
}

.loader-history__container {
  margin: calc(10px * var(--scale-coefficient)) 0;
  padding: 0 calc(15px * var(--scale-coefficient));
  height: calc(100vh - 210px * var(--scale-coefficient));
  overflow-y: auto;
}
