.file-form {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  
  &__fields {
    display: grid;
    grid-row-gap: 8px;
  }
  
  &__title {
    color: #444;
    
    &_mt {
      margin-top: 5px;
    }
  }
  
  &__buttons {
    display: flex;
    justify-content: space-between;
    margin-top: auto;
  }
  
  &__no-data {
    margin-top: 26px;
  }
  
  &__loading {
    width: 100%;
    padding: 20px 0 20px;
    border: 1px solid var(--dark-cyan);
    border-radius: 3px;
    text-align: center;
  }

  &__progress {
    display: block;
  }

  &__error {
    word-break: break-word;
  }
}