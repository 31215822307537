.map-icons-modal__main {
    padding: calc(20px * var(--scale-coefficient));
    width: 400px;
    display: flex;
    flex-wrap: wrap;
    overflow-x: hidden;
}

.map-icons-modal__buttons {
    display: flex;
    width: 100%;
    padding: 12px 16px;
    border-top: 1px solid var(--light-grey);
}

.map-icons-modal__icon-container {
    width: calc(48px * var(--scale-coefficient));
    height: calc(48px * var(--scale-coefficient));
    border: 1px solid var(--light-grey);
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: calc(10px * var(--scale-coefficient));
    margin-bottom: calc(10px * var(--scale-coefficient));
    cursor: pointer;
}

.map-icons-modal__icon {
    width: calc(30px * var(--scale-coefficient));
    height: calc(30px * var(--scale-coefficient));
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}

.map-icons-modal__selected {
    background-color: #DAE7ED;
}
