.error-page {
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    background: white;
}

.error-page svg {
    max-width: none !important;
    max-height: none !important;
}

@media screen and (max-width: 700px) {
    .error-page {
        flex-direction: column;
        justify-content: center;
    }
}
