.widget-table-header-action__wrap,
.widget-table-header-action__column-header {
    display: flex;
    align-items: center;
    height: 100%;
}

.widget-table-header-action__wrap > div {
    justify-content: center;
}

.widget-table-header-action {
    padding: 0 calc(8px * var(--scale-coefficient));
    background: transparent;
    border-radius: var(--base-border-radius);
    display: flex;
    align-items: center;
    font-size: inherit;
    min-width: 0;
}

.widget-table-header-action.Mui-disabled {
    pointer-events: all;
}

.widget-table-header-action:hover {
    background: rgba(255, 255, 255, 0.3);
}

.widget-table-header-action__opened {
    background: rgba(255, 255, 255, 0.3);
}

.widget-table-header-action__button-title {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.widget-table-header-action__title {
    font-size: calc(14px * var(--scale-coefficient));
    font-weight: 500;
    color: var(--grey);
}

.widget-table-header-action__sorting_icon {
    cursor: pointer;
}

.widget-table-header-action__sorting_priority {
    position: absolute;
    top: -12px;
    font-size: calc(14px * var(--scale-coefficient));
}

.widget-table-header-action__sorting_icon svg {
    display: block;
}

.widget-table-header-action__arrow {
    margin-left: calc(8px * var(--scale-coefficient));
}

.widget-table-header-action__arrow svg {
    display: block;
}

.widget-table-header-action__arrow-up svg{
    -moz-transform: scale(-1, -1);
    -o-transform: scale(-1, -1);
    -webkit-transform: scale(-1, -1);
    transform: scale(-1, -1);
}

.widget-table-header-action__filters-notice {
    width: calc(20px * var(--scale-coefficient));
    height: calc(20px * var(--scale-coefficient));
    background: var(--dark-cyan);
    border-radius: 50%;
    font-size: calc(14px * var(--scale-coefficient));
    font-weight: 700;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: rgba(24, 54, 100, 1);
}

.widget-table-header-action__filters {
    width: calc(400px * var(--scale-coefficient));
    padding: calc(12px * var(--scale-coefficient)) calc(16px * var(--scale-coefficient));
}
