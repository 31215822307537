.dashboard-filters__container {
  border: 1px solid var(--dark-cyan);
  border-top: none;
  border-bottom: none;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: calc(400px * var(--scale-coefficient));
  box-sizing: border-box;
  z-index: 100;
  display: flex;
  flex-direction: column;
  background-color: var(--main-backdrop-color);
}

.dashboards-preview + .dashboard-filters__container {
  top: var(--panel-size);
}

.dashboard-filters__heading {
  height: var(--panel-size);
  padding: calc(20px * var(--scale-coefficient));
  border-bottom: 1px solid var(--dark-cyan);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.dashboard-filters__title {
  font-family: Manrope,sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: calc(18px*var(--scale-coefficient));
  line-height: calc(24px*var(--scale-coefficient));
  color: #444;
}

.dashboard-filters__close {
  border: 1px solid var(--dark-cyan);
  width: calc(46px * var(--scale-coefficient));
  min-width: calc(46px * var(--scale-coefficient));
  height: calc(46px * var(--scale-coefficient));
  padding: 0;
}

.dashboard-filters__wrappers {
  margin-bottom: auto;
  padding: calc(20px * var(--scale-coefficient));
  overflow-y: auto;
}

.dashboard-filters__list .filter-heading {
  display: block;
}

.dashboard-filters__drop-filters-wrapper {
  padding: calc(20px * var(--scale-coefficient));
  border-top: 1px solid var(--dark-cyan);
}
