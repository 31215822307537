.hover {
  padding-right: 36px;
}

.hover::before {
  content: '';
  position: absolute;
  top: 50%;
  left: 0px;
  width: 2px;
  height: calc(30px * var(--scale-coefficient));
  background-color: currentColor;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  transform: translate(0, -50%);
  opacity: 0;
}

.hover:hover::before {
  opacity: 1;
}
