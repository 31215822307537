.map-modal__source-field.source-field {
    font-weight: 500;
    font-size: calc(14px * var(--scale-coefficient));
    line-height: calc(14px * var(--scale-coefficient));
    box-shadow: none;
    height: calc(50px * var(--scale-coefficient));
}

.map-modal__source-field.source-field:last-child {
    margin-bottom: 4px;
}

.map-modal__source-field .source-field__type {
    margin-right: calc(10px * var(--scale-coefficient));
    vertical-align: middle;
    display: flex;
}

.map-modal__source-field .source-field__name {
    display: inline-block;
    color: var(--title-color);
}

.map-modal__source-field .source-field__functions {
    display: flex;
    margin: 0 0 0 auto;
    border: 1px solid var(--dark-cyan);
    border-radius: var(--base-border-radius);
    align-items: center;
}

.map-modal__source-field .source-field__value {
    color: var(--title-color);
    padding: 0 calc(8px * var(--scale-coefficient));
}

.map-modal__source-field .source-field__button:not(:first-child) {
    border-left: 1px solid var(--dark-cyan);
}

.map-modal__source-field .source-field__delete-aggregation:hover:before {
    position: absolute;
    content: '';
    top: calc(-13px * var(--scale-coefficient));
    left: -1px;
    width: 100%;
    height: calc(36px * var(--scale-coefficient));
    border: 1px solid var(--red-normal);
    border-radius: var(--base-border-radius);
    background: var(--white);
    z-index: 1;
    cursor: pointer;
}

.map-modal__source-field .source-field__button-delete {
    display: none;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
    cursor: pointer;
}

.map-modal__source-field .source-field__delete-aggregation:hover .source-field__button-delete {
    display: flex;
}
