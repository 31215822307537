.callout-line-label {
  position: fixed;
  font-family: Roboto, sans-serif;
  font-size: 15px;
  line-height: 17px;
  padding: 0 6px;
  white-space: nowrap;
}

.callout-line-label__text {
  font-weight: 400;
  color: var(--grey);
  overflow: hidden;
  text-overflow: ellipsis;
}

.callout-line-label__value {
  font-weight: 500;
  color: var(--black);
  overflow: hidden;
  text-overflow: ellipsis;
}
