.select-fields-panel {
  &__title-section {
    width: 100%;
    height: calc(78px * var(--scale-coefficient));
    min-height: calc(78px * var(--scale-coefficient));
    margin: 0;
    display: flex;
    align-items: center;
    padding: 0 calc(20px * var(--scale-coefficient));
    border-bottom: 1px solid var(--light-grey);
  }
  &__title-section-title {
    font-family: Manrope;
    font-style: normal;
    font-weight: bold;
    font-size: calc(18px * var(--scale-coefficient));
    line-height: calc(22px * var(--scale-coefficient));
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    color: #444;
    margin-right: calc(6px * var(--scale-coefficient));
  }
  &__title-section-left {
    display: flex;
    flex-grow: 1;
    align-items: center;
    margin-right: calc(10px * var(--scale-coefficient));
  }
  &__info-icon {
    margin-left: auto;
  }
  &__title-section-right {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;

    & > button:last-child {
      margin-left: calc(12px * var(--scale-coefficient));
    }
  }
  &__range-button-hidden {
    display: none;
  }
}
