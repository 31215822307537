.connection-type-list {
  height: 100%;
  padding: calc(16px * var(--scale-coefficient)) calc(12px * var(--scale-coefficient)) calc(18px * var(--scale-coefficient)) calc(20px * var(--scale-coefficient));
}

.connection-type-list__title {
  margin-bottom: calc(12px * var(--scale-coefficient));
  color: var(--black);
  font-family: 'Manrope', 'Arial', sans-serif;
  font-size: calc(14px * var(--scale-coefficient));
  font-weight: bold;
  line-height: calc(46px * var(--scale-coefficient));
}

.connection-type-list__btn:before {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url('../../../assets/images/bg-scheme.png');
  background-position: center;
  background-size: 50%;
  opacity: 0.3;
}

.connection-type-list__connection-form {
  position: absolute;
  overflow: auto;
  top: 0;
  left: 100%;
  z-index: 10;
  box-shadow: 0 4px 10px rgba(58, 130, 228, 0.12),
    0 4px 10px rgba(0, 0, 0, 0.06);
}

.connection-type-list__options {
  overflow-y: scroll;
  height: calc(100vh - 310px * var(--scale-coefficient));
  padding-right: 8px;
}

.connection-type-list__input {
  margin-bottom: calc(18px * var(--scale-coefficient));
  margin-right: calc(13px * var(--scale-coefficient));
}
