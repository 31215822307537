.setting-container__container {
    border-left: 1px solid var(--dark-cyan);
    border-right: 1px solid var(--dark-cyan);
    border-top: none;
    position: absolute;
    top: 0;
    left: calc(302px * var(--scale-coefficient));
    bottom: 0;
    width: calc(400px * var(--scale-coefficient));
    background: var(--white);
    display: flex;
    flex-direction: column;
    box-shadow: 0 4px 10px rgb(0 0 0 / 6%), 0 4px 10px rgb(0 0 0 / 6%);
}

.setting-container__heading {
    height: var(--panel-size);
    padding: calc(20px * var(--scale-coefficient));
    border-bottom: 1px solid var(--dark-cyan);
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.setting-container__title {
    font-family: Manrope, sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: calc(18px * var(--scale-coefficient));
    line-height: calc(24px * var(--scale-coefficient));
    color: var(--black);
}

@media screen and (max-width: 900px) {
    .setting-container__container {
        left: calc(264px * var(--scale-coefficient));
    }
}
