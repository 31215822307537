.select-fields-panel {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  background: var(--white);
  width: calc(730px * var(--scale-coefficient));
  height: 100%;
  position: absolute;
  right: 0;
  z-index: 100;
  box-shadow: 0px 4px 10px rgba(58, 130, 228, 0.12), 0px 4px 10px rgba(0, 0, 0, 0.06);

&__sql-body {
   padding: calc(20px * var(--scale-coefficient));
   display: flex;
   flex-direction: column;
   flex: 1;
 }

  &__svg-pic {
    margin-right: 0 !important;
  }

  &__alert {
       word-break: break-word;
  }
}
