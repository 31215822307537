.connection-ftp {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  width: calc(303px * var(--scale-coefficient));
  padding: calc(16px * var(--scale-coefficient)) calc(20px * var(--scale-coefficient)) calc(18px * var(--scale-coefficient));
  background-color: var(--white);
  border-right: 1px solid #DAE7ED;
  
  &__filter {
    padding-top: calc(19px * var(--scale-coefficient)) !important;
    padding-bottom: calc(7px * var(--scale-coefficient)) !important;
    height: calc(20px * var(--scale-coefficient)) !important;
  }

  &__title-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: calc(12px * var(--scale-coefficient));
  }

  &__title {
    font-size: calc(14px * var(--scale-coefficient));
    font-family: 'Manrope', 'Arial', sans-serif;
    font-weight: bold;
    color: var(--black);
  }

  &__files-section {
    overflow-y: auto;
    overflow-x: hidden;
    margin: calc(12px * var(--scale-coefficient)) 0 calc(10px * var(--scale-coefficient)) 0;
  }

  &__buttons {
    display: flex;
    justify-content: space-between;
    margin-top: auto;
  }

  &__table-preview {
    position: absolute;
    top: 0;
    left: 100%;
    height: 100%;
    width: calc(100vw - 230%);
    z-index: 10;
  }

  &__new-file-panel {
    position: absolute;
    top: 0;
    left: 100%;
    height: 100%;
    z-index: 10;
    box-shadow: 0px 4px 10px rgba(58, 130, 228, 0.12),
                0px 4px 10px rgba(0, 0, 0, 0.06);
  }
}

.ftp-notification-container {
  margin-bottom: calc(10px * var(--scale-coefficient));
}
