.connection-file {
  display: flex;
  justify-content: space-between;

  &__title {
    width: calc(195px * var(--scale-coefficient));
    margin-top: auto;
    margin-bottom: auto;
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: calc(14px * var(--scale-coefficient));
    line-height: calc(14px * var(--scale-coefficient));
    color: var(--black);
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &_blue {
    color: var(--primary-color) !important;
  }

  &__label {
    width: calc(180px * var(--scale-coefficient));
    text-overflow: ellipsis;
    overflow: hidden;
  }

  &__control {
    margin-right: 0;
  }
}
