.column-rename-options__main {
    display: flex;
    flex-direction: column;
    padding: calc(20px * var(--scale-coefficient)) calc(20px * var(--scale-coefficient)) 0;
    width: calc(440px * var(--scale-coefficient));
}

.column-rename-options__main > * {
    margin-bottom: calc(16px * var(--scale-coefficient));
}

