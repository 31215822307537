.speedometer__root {
    width: 100%;
    flex-direction: column;
    min-width: 300px;
    min-height: 150px;
    flex-grow: 1;
}

.speedometer__svg {
    left: 0;
    vertical-align: top;
}

.speedometer-center__wrap {
    position: absolute;
    top: 0;
    left: 0;
}

.speedometer-center__content {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin: auto;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
  
  .speedometer-center__num {
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 32px;
    line-height: 37px;
    color: var(--black);
    text-overflow: ellipsis;
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    padding-left: 10px;
    padding-right: 10px;
    max-width: 100%;
}
  
  .speedometer-center__description {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    color: #808080;
    margin-top: 8px;
    text-align: center;
    max-width: 100%;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    width: 100%;
    overflow: hidden;
    white-space: pre-wrap;
    word-break: break-word;
    display: -webkit-box;
}

.top-translateY__reset {
    top: 0;
    transform: translateY(0);
}

.path {
    stroke-dasharray: 1000;
    stroke-dashoffset: 1000;
    animation: 0.6s ease-in 0s 1 alternate dash;
}

.lateralText {
    font-family: Manrope;
    font-style: normal;
    font-weight: bold;
    line-height: 18px;

}

@keyframes dash {
    from {
        stroke-dashoffset: 0;
    }
    to {
        stroke-dashoffset: 1000;
    }
}
