.field-item {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background: var(--white);
  margin-left: 0;
  margin-right: 0;
  margin-bottom: calc(14px * var(--scale-coefficient));
  padding: calc(14px * var(--scale-coefficient)) calc(10px * var(--scale-coefficient));
  border: 1px solid var(--dark-cyan);
  border-radius: 3px;
  box-shadow: none;

  &__title {
    font-family: Roboto, Arial, sans-serif !important;
    font-style: normal;
    font-weight: 600;
    font-size: calc(14px * var(--scale-coefficient));
    line-height: calc(14px * var(--scale-coefficient));
    color: var(--title-color);
    width: 195px;
    margin-top: auto;
    margin-bottom: auto;
     text-overflow: ellipsis;
     white-space: nowrap;
  }

  &__icons {
     margin-left: auto;
  display: flex;
   }

  &__icons > * {
     margin-left: calc(6px * var(--scale-coefficient));
   }

  &__type-icon {
    margin: 0 calc(15px * var(--scale-coefficient)) 0 0;
  }

  &__tick-mark {
    box-shadow: none;
    border: none;
    margin: 0;
  }

  &_blue {
    color: var(--primary-color) !important;
    box-shadow: none;
  }
}
