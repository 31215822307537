.layout {
  min-height: 100vh;
  display: grid;
  grid-template-columns: var(--panel-size) 1fr;
  grid-template-rows: var(--panel-size) 1fr;
  grid-template-areas: 'logo header' 'aside content';
  color: var(--title-color);
}

.layout__no-header {
  grid-template-areas: 'logo content' 'aside content';
}

.layout__aside {
  grid-area: aside;
  background: linear-gradient(180deg, var(--panel-color), var(--primary-color));
  overflow: hidden;
  position: fixed;
   left: 0;
   top: var(--panel-size);
   bottom: 0;
   width: var(--panel-size);
   z-index: 9;
}

.layout__content {
  grid-area: content;
  overflow-y: auto;
  height: calc(100vh - var(--panel-size));
  background-color: var(--backdrop-color);
}

.layout__container-area {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 303px;
  border-right: var(--light-grey) solid 1px;
}

.layout__logo {
  position: fixed;
  top: 0;
  left: 0;
  grid-area: logo;
  display: flex;
  align-items: center;
  justify-content: center;
  width: var(--panel-size);
  height: var(--panel-size);
  color: var(--white);
  background-color: var(--panel-color);
  z-index: 106;
}

.layout__logo-container {
  width: calc(46px * var(--scale-coefficient));
  height: calc(46px * var(--scale-coefficient));
  border-radius: var(--base-border-radius);
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.layout__logo-icon,
svg.layout__logo-icon-svg {
  max-width: calc(40px * var(--scale-coefficient)) !important;
  max-height: calc(40px * var(--scale-coefficient)) !important;
  width: calc(40px * var(--scale-coefficient)) !important;
  height: calc(40px * var(--scale-coefficient)) !important;
}

@media (max-width: 500px) {
  .layout {
    grid-template-areas: 'header' 'content';
    grid-template-columns: 1fr;
  }

  .layout-header {
    position: static;
  }

  .layout-header__organization {
    width: auto;
  }

  .layout__logo {
    display: none;
  }

  .layout__aside {
    display: none;
  }
}
