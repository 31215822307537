.widget-donut-container {
  display: flex;
  flex-grow: 1;
  min-height: 200px;
}

.donut-center__content {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: auto;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.donut-center__text {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  text-overflow: ellipsis;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  padding-left: 10px;
  padding-right: 10px;
  max-width: 100%;
}

.donut-center__description {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  margin-top: 8px;
  text-align: center;
  max-width: 100%;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  width: 100%;
  overflow: hidden;
  white-space: pre-wrap;
  word-break: break-word;
  display: -webkit-box;
}

.donut-section__text {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;
  color: var(--white);
}
