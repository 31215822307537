.error-page__info-container {
    display: flex;
    flex-direction: column;
}

.error-page__title {
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 700;
    font-size: calc(32px * var(--scale-coefficient));
    line-height: calc(48px * var(--scale-coefficient));
    color: var(--title-color);
}

.error-page__description {
    margin: calc(16px * var(--scale-coefficient)) 0 calc(40px * var(--scale-coefficient));
    display: flex;
    flex-direction: column;
}

.error-page__description p {
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 500;
    font-size: calc(20px * var(--scale-coefficient));
    line-height: calc(32px * var(--scale-coefficient));
    color: var(--title-color);
}

@media screen and (max-width: 700px) {
    .error-page__info-container {
        order: 2;
    }
}
