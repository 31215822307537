.graphic-type {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: calc(24px * var(--scale-coefficient));
}
.view-type {
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(20px * var(--scale-coefficient));
  margin-bottom: 0 !important;
  margin-right: -8px;
  overflow-y: auto;
  max-height: calc(100% - 462px * var(--scale-coefficient));
  min-height: calc(76px * var(--scale-coefficient));
}

.view-type__item {
  margin-right: calc(5px * var(--scale-coefficient));
  margin-bottom: calc(10px * var(--scale-coefficient));
  width: 31%;
  aspect-ratio: 1/1;
}

.widget-name {
  margin-bottom: calc(15px * var(--scale-coefficient));
}

.settings-item {
  border-radius: var(--base-border-radius);
  text-align: center;
  border: 1px solid var(--dark-cyan);
  cursor: pointer;
  transition: all 0.3s;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: end;
  padding-bottom: calc(6px * var(--scale-coefficient));
}

.settings-item img {
  width: calc(24px * var(--scale-coefficient));
  height: calc(24px * var(--scale-coefficient));
}

.settings-item.active {
  background-color: #EDF1F8;
}

.settings-item:hover {
  background-color: #EDF1F8;
}

.settings-item svg {
  vertical-align: top;
}

.settings-title {
  font-family: Manrope, sans-serif;
  font-size: calc(14px * var(--scale-coefficient));
  color: #444;
}

.settings-title:not(:first-child) {
  margin-top: calc(12px * var(--scale-coefficient));
}

.settings-title + div {
  margin-bottom: calc(10px * var(--scale-coefficient));
}

.setting-field {
  width: 100%;
  margin-top: calc(20px * var(--scale-coefficient));
}

.setting-input-field {
  width: 100%;
}

.settings-textarea {
  min-width: 100%;
  max-width: 100%;
  margin-top: 12px;
  background: var(--white);
  border: 1px solid var(--dark-cyan);
  box-sizing: border-box;
  border-radius: 3px;
  padding: 11px;
  color: var(--dark-grey);
  margin-bottom: 10px;
}

.settings-textarea:focus {
  border-color: var(--primary-color);
  outline: none
}

.settings-item__name {
  display: block;
  color: var(--title-color);
  margin-top: calc(10px * var(--scale-coefficient));
}

.widget-settings {
  position: relative;
  z-index: 15;
  background: var(--white);
  height: 100%;
}

.settings-modal {
  position: absolute;
  overflow: auto;
  left: calc(-400px * var(--scale-coefficient));
  bottom: 0;
  top: 0;
  width: calc(400px * var(--scale-coefficient));
  background-color: var(--white);
  border-left: 1px solid var(--dark-cyan);
  border-right: 1px solid var(--dark-cyan);
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.06), 0px 4px 10px rgba(0, 0, 0, 0.06);
  transition:  0.5s;
  display: none;
}

.settings-modal--opened {
  display: flex;
  flex-direction: column;
  overflow: visible;
}

.settings-modal--color-palette-opened {
  left: calc(-400px * var(--scale-coefficient));
  overflow: visible;
  display: flex;
  flex-direction: column;
}

.settings-modal__header {
  height: var(--panel-size);
  padding: calc(15px * var(--scale-coefficient)) calc(20px * var(--scale-coefficient));
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid var(--dark-cyan);;
}

.settings-modal__title {
  font-family: "Manrope";
  font-size: calc(18px * var(--scale-coefficient));
  color: var(--black);
}

.settings-modal_content {
  padding: calc(22px * var(--scale-coefficient)) calc(20px * var(--scale-coefficient));
  overflow-x: scroll;
}

.settings-modal__item {
  margin-bottom: calc(15px * var(--scale-coefficient));
}

.settings-modal__item-title {
  font-family: Manrope;
  font-style: normal;
  font-weight: 700;
  font-size: calc(14px * var(--scale-coefficient));;
  line-height: calc(20px * var(--scale-coefficient));
  color: #444;
  margin-bottom: calc(18px * var(--scale-coefficient));
}

.settings-modal__title-small {
  margin-top: calc(22px * var(--scale-coefficient));
  margin-bottom: calc(15px * var(--scale-coefficient));
  display: flex;
  flex-direction: row;
}

.settings-color-palette-modal__color-container {
  width: 100%;
  padding: calc(16px * var(--scale-coefficient));
  border-radius: var(--base-border-radius);
  background-color: rgb(237, 241, 248);
  display: flex;
}

.settings-color-palette-modal__content {
  padding: calc(22px * var(--scale-coefficient)) calc(20px * var(--scale-coefficient));
  overflow-x: scroll;
  flex-grow: 1;
}

.settings-color-palette-modal__color-container-icon {
  align-self: center;
  margin: auto 0 auto calc(12px * var(--scale-coefficient));
}

.settings-color-palette-modal__color-container-icon.clickable {
  cursor: pointer;
}

.settings-color-palette-modal__color-container-icon svg {
  vertical-align: top;
}

.settings-color-palette-modal__buttons {
  padding: calc(5px * var(--scale-coefficient)) calc(20px * var(--scale-coefficient)) calc(20px * var(--scale-coefficient));
}

.settings-color-palette-modal__buttons button {
  margin-right: calc(20px * var(--scale-coefficient));
}

.settings-modal__icons-container {
  display: flex;
  flex-wrap: wrap;
}

.settings-modal__icon-container {
  width: calc(48px * var(--scale-coefficient));
  height: calc(48px * var(--scale-coefficient));
  border: 1px solid var(--light-grey);
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: calc(10px * var(--scale-coefficient));
  margin-bottom: calc(10px * var(--scale-coefficient));
}

.settings-modal__icon {
  width: calc(30px * var(--scale-coefficient));
  height: calc(30px * var(--scale-coefficient));
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
