.main-menu {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  overflow: hidden;
}

.main-menu__item {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: var(--panel-size);
  color: var(--white);
  transition: all .3s;
}

.main-menu__item_active {
  box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.15);
  border-top: none;
}

.main-menu__item_active:before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 3px;
  height: 100%;
  background-color: var(--white);
}

.main-menu__item_active:after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: var(--white);
  opacity: 0.15;
}

.main-menu__item svg {
  vertical-align: top;
  height: calc(28px * var(--scale-coefficient)) !important;
  width: calc(28px * var(--scale-coefficient)) !important;
  max-width: none !important;
  max-height: none !important;
}

.main-menu__item img {
  height: calc(28px * var(--scale-coefficient));
  width: calc(28px * var(--scale-coefficient));
}
