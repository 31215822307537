.list-item {
  box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2),
              0px 1px 1px 0px rgba(0,0,0,0.14),
              0px 1px 3px 0px rgba(0,0,0,0.12);
}

.group-container {
  margin-top: calc(18px * var(--scale-coefficient));
  max-width: 1550px;
  border: 1px solid var(--dark-cyan);
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.06), 0px 4px 10px rgba(58, 130, 228, 0.12);
  border-radius: var(--base-border-radius);
  background: var(--white);
}

.icon-arrow {
  width: calc(22px * var(--scale-coefficient));
  height: calc(22px * var(--scale-coefficient));
  transition: ease-in-out 0.3s;
}

.icon-arrow-up {
  transform: rotate(-180deg);
}

.counter {
  display: flex;
  align-items: center;
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
}

.counter__title {
  font-family: Manrope;
  font-style: normal;
  font-weight: 700;
  font-size: calc(18px * var(--scale-coefficient));
  line-height: calc(18px * var(--scale-coefficient));
  max-width: calc(600px * var(--scale-coefficient));
  text-overflow: ellipsis;
}

.counter__projects {
  font-size: calc(14px * var(--scale-coefficient));
}

.counter-icon {
  margin-left: 9px;
  margin-right: 6px;
}

.group-title-panel {
  border-radius: var(--base-border-radius);
  background-color: var(--white);
  padding-right: calc(20px * var(--scale-coefficient));
  padding-left: calc(20px * var(--scale-coefficient));
}

.group-title-panel_open {
  border-bottom: 1px solid var(--light-grey);
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.group-title-panel:hover {
  background-color: rgba(146, 155, 172, 0.008);
}

.group-body {
  width: 100%;
  background-color: var(--white);
  border-bottom-left-radius: var(--base-border-radius);
  border-bottom-right-radius: var(--base-border-radius);
}


@media (min-width: 1600px) {
  .group-container {
    margin-left: auto;
    margin-right: auto;
  }
}

@media all and (max-width: 768px) {
  .group-title-panel {
    justify-content: space-between;
  }

  .counter__title {
    white-space: nowrap;
  }

  .counter__projects {
    white-space: nowrap;
  }

  .group-controls-item {
    padding: 0 10px
  }
}

@media all and (max-width: 420px) {
  .counter__projects, .counter-icon {
    display: none;
  }
}
