.layout-header {
  grid-area: header;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: var(--panel-size);
  background-color: var(--header-backdrop-color);
  border-bottom: 1px solid #dae7ed;
  position: fixed;
  top: 0;
  left: var(--panel-size);
  right: 0;
  z-index: 100;

  &__tiles {
    margin-left: auto;
  }

  &__title-container {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-right: auto;
    margin-left: calc(20px * var(--scale-coefficient));
    font-family: Manrope;
    font-style: normal;
    font-weight: bold;
    font-size: calc(20px * var(--scale-coefficient));
    line-height: calc(22px * var(--scale-coefficient));
  }
}

.header-widget-buttns {
  display: flex;
}

.header-widget-buttns > *:not(:first-child) {
    margin-left: calc(12px * var(--scale-coefficient));
}

.header-widget-buttns .MuiButton-contained {
    box-shadow: none;
}

.header-widget-buttns .MuiButton-contained:hover {
    box-shadow: var(--shadow-button-outline-hover);
}

div.project-card-update__floating {
  margin: 0;
}

@media screen and (max-width: 900px) {
    .layout-header__organization {
        min-width: calc(265px * var(--scale-coefficient));
    }
}

@media screen and (max-width: 860px) {
    .layout-header__organization {
        border: none;
    }

    .layout-header__title {
        display: none;
    }
}

@media screen and (max-width: 600px) {
    .layout-header__title-container {
        display: none;
    }

    .layout-header__organization {
        min-width: calc(60px * var(--scale-coefficient));
    }
}

@media screen and (max-width: 500px) {
    .layout-header__organization-block{
        display: none;
    }
}
