.transformation-panel {
  padding: calc(20px * var(--scale-coefficient));

  &__textarea-section {
    border: 1px solid var(--dark-cyan);
    border-radius: 3px;
    height: auto;
    margin-top: calc(10px * var(--scale-coefficient));
  }
  &__textarea-section-description {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: calc(14px * var(--scale-coefficient));
    line-height: calc(18px * var(--scale-coefficient));
    color: var(--dark-grey);
    padding: calc(9px * var(--scale-coefficient)) calc(11px * var(--scale-coefficient));
  }
  &__textarea-section-main {
    display: flex;
    flex-direction: row;
  }
  &__textarea-section-formulas-list {
    width: auto;
    margin-left: calc(12px * var(--scale-coefficient));
    margin-right: calc(12px * var(--scale-coefficient));
  }
  &__textarea-section-textarea {
    padding-top: calc(5px * var(--scale-coefficient));
    width: 100%;
    border: none;
    background-color: transparent;
    resize: none;
    outline: none;
  }
  &__examples {
     margin-top: calc(20px * var(--scale-coefficient));
  }
  &__popover {
    overflow-x: initial;
    overflow-y: initial;
  }
}
