.main-page {
  display: flex;
  flex-direction: column;
  padding: 15px 20px;
  height: 100%;
}

.main-page__svg svg {
  display: block;
}

.group-title-block {
  display: flex;
  max-width: 1550px;
  align-items: center;
  width: 100%;
}

.groups-mode-title {
  overflow: hidden;
  font-size: calc(20px * var(--scale-coefficient));
  font-style: normal;
  font-family: Manrope, sans-serif;
  font-weight: bold;
  line-height: calc(27px * var(--scale-coefficient));
  white-space: nowrap;
  margin-right: calc(21px * var(--scale-coefficient));
  text-overflow: ellipsis;
}

@media (min-width: 1600px) {
  .group-title-block {
    margin-left: auto;
    margin-right: auto;
  }
}

@media all and (max-width: 860px) {
  .group-title-block {
    flex-wrap: wrap;
  }

  .groups-mode-select {
    display: flex;
    align-items: center;
    order: 1
  }

  .groups-mode-title {
    margin-bottom: 10px;
  }

  .create-group-button {
    order: 1;
    margin-left: auto;
  }

  .groups-search-panel {
    order: 2;
    margin-top: 10px;
    margin-left: 0;
    width: 100%;
  }

  .groups-mode-select {
    margin-bottom: 10px;
  }
}

@media all and (max-width: 1024px) {
  .main-page__button-group {
    display: none;
  }
}

@media all and (max-width: 760px) {
  .create-group-button > div {
    margin-left: 0;
  }
}

@media all and (max-width: 500px) {
  .groups-mode-title {
    width: 100%;
  }

  .groups-search-panel {
    height: auto;
    width: 100%;
    flex-direction: column;
  }

  .groups-search-panel > div {
    width: 100%;
    margin: 0 0 10px 0;
  }

  .groups-search-panel > div:last-child {
    margin: 0;
  }

  .groups-mode-select {
    display: none;
  }

  .create-group-button {
    margin-left: 0;
  }
}

