.loader-schedule {
  width: calc(900px * var(--scale-coefficient));
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;

  &__title {
    display: flex;
    align-items: center;
    padding: calc(15px * var(--scale-coefficient)) calc(20px * var(--scale-coefficient));
  }

   &__title-text {
      font-family: Manrope;
      font-style: normal;
      font-weight: 700;
      font-size: calc(18px * var(--scale-coefficient));
      line-height: calc(22px * var(--scale-coefficient));
      margin-right: auto;
      color: #444;
    }

  &__container {
     display: flex;
     flex-direction: column;
     flex: 1;
     align-items: center;
    padding: calc(20px * var(--scale-coefficient));
  }

.loader-schedule-body {
    width: 100%;
    display: flex;
    flex-direction: column;

&__fields {
    display: flex;
    flex-direction: column;
}

&__field {
    margin-top: calc(16px * var(--scale-coefficient));
}

&__field:first-child {
    margin-top: calc(20px * var(--scale-coefficient));
}

}
  .no-schedule {
    display: flex;
    flex-flow: column wrap;
    align-items: center;
    justify-content: center;
    margin-top: calc(150px * var(--scale-coefficient));

    svg {
      max-width: calc(80px * var(--scale-coefficient)) !important;
      max-height: calc(90px * var(--scale-coefficient)) !important;
    }

&__title {
   font-family: 'Manrope';
   font-style: normal;
   font-weight: 700;
   font-size: calc(16px * var(--scale-coefficient));
   line-height: calc(20px * var(--scale-coefficient));
   color: var(--title-color);
   margin-bottom: calc(8px * var(--scale-coefficient));
   margin-top: calc(20px * var(--scale-coefficient));
 }

&__subtitle {
   font-family: 'Roboto';
   font-style: normal;
   font-weight: 500;
   font-size: calc(14px * var(--scale-coefficient));
   line-height: calc(18px * var(--scale-coefficient));
   color: var(--black);
 }
  }

  .launchers {
    width: 100%;
    padding: calc(20px * var(--scale-coefficient)) calc(20px * var(--scale-coefficient)) calc(10px * var(--scale-coefficient)) calc(20px * var(--scale-coefficient));
    margin-top: 20px;
    border: 1px solid var(--dark-cyan);
    border-radius: 3px;
    background-color: rgba(197, 217, 232, 0.1);

    &__title {
      font-weight: bold;
      color: var(--black);
    }

    &__list {
      width: 100%;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(5, 1fr);
      grid-auto-flow: column;
      grid-column-gap: calc(20px * var(--scale-coefficient));
      list-style-type: none;
    }

    &__item {
      padding: calc(15px * var(--scale-coefficient)) 0;
      border-bottom: 1px solid var(--dark-cyan);
      color: var(--black);

      &:nth-child(5n) {
        padding-bottom: 0;
        border-bottom: none;
      }
    }
  }

&__error {
    width: 100%;
    margin: calc(20px * var(--scale-coefficient));
 }
}
