.history-element {
  &:before {
    content: '';
    position: absolute;
    top: 50%;
    left: -2px;
    width: 2px;
    height: calc(30px * var(--scale-coefficient));
    background-color: currentColor;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    transform: translate(0, -50%);
  }

  &__default:before {
    background-color: #000;
  }

  &__error:before {
    background-color: var(--red-normal);
  }

  &__done:before {
    background-color: var(--success-color);
  }

  &__inner-text {
    display: flex;
    font-weight: bold;
  }

  &__count {
    display: flex;
    align-items: center;
    min-width: calc(150px * var(--scale-coefficient));
    margin-left: auto;
  }

  &__count-icon {
    display: inline-flex;
    margin-right: calc(18px * var(--scale-coefficient));
  }

  &__icon-count {
    display: inline-flex;
    margin-right: calc(18px * var(--scale-coefficient));
  }

  &__icon-arrow {
    margin-left: auto;
    width: calc(20px * var(--scale-coefficient));
    height: calc(20px * var(--scale-coefficient));
    transition: ease-in-out 0.3s;
  }

  &__icon-arrow-up {
    transform: rotate(-180deg);
  }

  &__alert-box {
    padding: calc(16px * var(--scale-coefficient));
  }
}
