.connections {
  display: flex;
  flex-direction: column;
  padding: calc(16px * var(--scale-coefficient)) calc(20px * var(--scale-coefficient));
  background-color: var(--white);
  border-right: 1px solid #DAE7ED;
  
  &__container-empty {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: auto auto calc(16px * var(--scale-coefficient));
  }

  &__right-panel {
    position: absolute;
    top: 0;
    left: 100%;
    width: calc(303px * var(--scale-coefficient));
    height: 100%;
    background-color: var(--white);
    z-index: 10;
    box-shadow: 0px 4px 10px rgba(58, 130, 228, 0.12),
                0px 4px 10px rgba(0, 0, 0, 0.06);

    & .MuiListItemIcon-root {
      min-width: auto;
    }
  }

  &__files-list {
    height: 100%;
  }
  
  &__container-connections {
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow-y: auto;
  }
}

.connections__container-empty + .connections__actions {
  margin-bottom: auto;
}

.connections__container-empty__title {
  color: var(--title-color);
  margin-top: calc(16px * var(--scale-coefficient));
}

.connections__container-empty__text {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: calc(14px * var(--scale-coefficient));
  line-height: calc(18px * var(--scale-coefficient));
  color: var(--black);
  text-align: center;
}

.connections__block {
  margin-bottom: calc(20px * var(--scale-coefficient));
}

.connections__container-connections > div:not(:last-child) {
  margin-bottom: calc(16px * var(--scale-coefficient));
}
