.sales-funnel-legend__container {
    overflow-x: auto;
    min-width: 200px;
    margin-right: 15px;
}

.sales-funnel-legend__item {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.sales-funnel-legend__number {
    border-radius: 3px;
    width: 32px;
    min-width: 32px;
    height: 32px;
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    margin-right: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.sales-funnel-legend__label {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    color: var(--black);
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow-y: hidden;
}
