.label-field {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: calc(8px * var(--scale-coefficient)) calc(12px * var(--scale-coefficient));
  background: var(--white);

  border: 1px solid var(--dark-cyan);
  box-sizing: border-box;

  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.06),
    0px 4px 10px rgba(58, 130, 228, 0.12);
  border-radius: var(--base-border-radius);
}

.label-field__label {
  font-size: calc(16px * var(--scale-coefficient));
  margin-left: calc(12px * var(--scale-coefficient));
  margin-right: auto;
  line-height: calc(28px * var(--scale-coefficient));
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  display: block;
}
