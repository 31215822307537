.dd-filters-panel {
    display: flex;
    padding: calc(12px * var(--scale-coefficient)) calc(12px * var(--scale-coefficient)) 0;
    max-height: calc(98px * var(--scale-coefficient));
}

.dd-filters-panel__container {
    overflow-y: auto;
    overflow-x: hidden;
    display: flex;
    flex-wrap: wrap;
}

.dd-filters-panel__item {
    display: flex;
    align-items: center;
    padding: calc(10px * var(--scale-coefficient)) calc(12px * var(--scale-coefficient));
    background: #EDF1F8;
    border-radius: var(--base-border-radius);
    margin-right: calc(8px * var(--scale-coefficient));
    margin-bottom: calc(5px * var(--scale-coefficient));
    max-width: min(calc(300px * var(--scale-coefficient)), 100%);
}

.dd-filters-panel__item:last-child {
    margin-right: 0;
}

.dd-filters-panel__item-value {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: calc(14px * var(--scale-coefficient));
    line-height: calc(18px * var(--scale-coefficient));
    color: var(--black);
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

}

.dd-filters-panel__item-multiple {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: calc(14px * var(--scale-coefficient));
    line-height: calc(18px * var(--scale-coefficient));
    color: var(--primary-color);
    margin-left: calc(4px * var(--scale-coefficient));
}

.dd-filters-panel__tooltip {
    display: flex;
    flex-direction: column;
}

.dd-filters-panel__tooltip span:not(:last-child) {
    margin-bottom: 4px;
}

.dd-filters-panel__item-value-tooltip {
    overflow: hidden;
    max-height: 50vh;
}
