.editable-text-container {
    display: flex;
}


.editable-text-container__text {
    margin-right: calc(10px * var(--scale-coefficient));
}

.editable-text-container__input-actions > *:not(:last-child) {
    margin-right: calc(6px * var(--scale-coefficient));
}
