.widget-pie-container {
  display: flex;
  flex-grow: 1;
  min-height: 200px;
}

.pie-section__text {
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;
  color: var(--white);
}

.pie-section__text-single {
  font-size: 20px;
}
