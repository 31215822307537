.bound-placeholder__container {
    padding: 0 calc(20px * var(--scale-coefficient));
    overflow: auto;
}

.bound-placeholder__no-connection-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: calc(71px * var(--scale-coefficient));
    text-align: center;
}

.bound-placeholder__no-connection-container svg {
    width: calc(100px * var(--scale-coefficient)) !important;
    height: calc(100px * var(--scale-coefficient)) !important;
    max-width: none !important;
    max-height: none !important;
}

.bound-placeholder__no-connection-heading {
    margin-top: calc(33px * var(--scale-coefficient));
    font-family: Manrope, serif;
    font-style: normal;
    font-weight: bold;
    font-size: calc(20px * var(--scale-coefficient));
    line-height: calc(27px * var(--scale-coefficient));
}

.bound-placeholder__no-connection-subheading {
    margin-top: calc(15px * var(--scale-coefficient));
    font-family: Roboto, serif;
    font-style: normal;
    font-weight: normal;
    font-size: calc(16px * var(--scale-coefficient));
    line-height: calc(19px * var(--scale-coefficient));
}

.bound-placeholder__no-connection-button {
    margin-top: calc(27px * var(--scale-coefficient));
    border: 1px solid var(--dark-cyan);
    box-sizing: border-box;
    box-shadow: inset -1px 1px 0 rgba(255, 255, 255, 0.0001);
    border-radius: 4px;
    width: calc(238px * var(--scale-coefficient));
    height: calc(46px * var(--scale-coefficient));
}
