.main-container {
  overflow-y: scroll;
  height: 100%;
}

.table-preview {
  height: 100% !important;
  background-color: var(--white);
  border: 1px solid var(--dark-cyan);
  display: flex;
  flex-direction: column;
  &:before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url("./assets/bg.png");
    background-repeat: repeat;
    background-size: 30px;
    opacity: 0.2;
  }

  &__head-panel {
    padding-top: calc(20px * var(--scale-coefficient));
    background: var(--white);
    display: flex;
    justify-content: space-between;
    padding-left: calc(20px * var(--scale-coefficient));
    padding-right: calc(20px * var(--scale-coefficient));
    margin-bottom: 0px;
  }

  &__title {
    color: var(--black);
    align-self: center;
  }

  &__error-container {
    flex-grow: 1;
    overflow: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: var(--white);
 }

  &__error-title {
    font-family: Manrope;
    font-weight: 700;
    font-size: 14px;
    margin-top: 10px;
  }

  &__info {
    width: calc(276px * var(--scale-coefficient));
    height: calc(40px * var(--scale-coefficient));
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__column-icon {
    padding-top: calc(5px * var(--scale-coefficient));
  }

  &__close {
    padding: calc(14px * var(--scale-coefficient)) calc(12px * var(--scale-coefficient)) calc(10px * var(--scale-coefficient)) calc(12px * var(--scale-coefficient));
    border: 1px solid var(--dark-cyan);
    box-sizing: border-box;
    border-radius: 4px;
    &:hover {
      background-color: var(--dark-cyan);
      cursor: pointer;
    }
  }

  &__header {
    height: calc(46px * var(--scale-coefficient));
    background: #EDF1F8;
    font-family: Roboto !important;
    font-style: normal;
    font-weight: 700;
    font-size: calc(14px * var(--scale-coefficient));
  }

  &__header-cell {
    font-family: Roboto !important;
    padding-top: calc(11px * var(--scale-coefficient)) !important;
    padding-bottom: calc(11px * var(--scale-coefficient)) !important;
    font-weight: bold !important;
    min-width: calc(150px * var(--scale-coefficient)) !important;
    box-sizing: border-box !important;
    background: #EDF1F8 !important;
    color: var(--black) !important;
  }

  &__cell {
    font-family: Roboto !important;
    padding-top: calc(12px * var(--scale-coefficient)) !important;
    padding-bottom: calc(12px * var(--scale-coefficient)) !important;
    padding-left: calc(20px * var(--scale-coefficient)) !important;
    padding-right: calc(20px * var(--scale-coefficient)) !important;
    min-width: calc(150px * var(--scale-coefficient)) !important;
    box-sizing: border-box !important;
    font-weight: normal;
    font-size: calc(16px * var(--scale-coefficient)) !important;
  }

  &__table {
    table-layout: auto;
  }

  &__body {
    overflow-y: scroll !important;
  }

  &__container {
    box-shadow: none !important;
  }

  &__placeholder-progress {
    background-color: var(--white);
    display: flex;
    align-items: center;
    flex-direction: column;
    flex-grow: 1;
  }
}

.resize-panel {
  position: relative;
  user-select: auto;
  width: auto;
  height: calc(273px * var(--scale-coefficient));
  box-sizing: border-box;
  flex-shrink: 0;
  z-index: 150;
  max-height: 60vh;
  background-color: var(--white);
}

.resize-panel > .main-container + div {
  position: absolute;
  top: calc(5px * var(--scale-coefficient));
  width: 100%;
  left: calc(10px * var(--scale-coefficient));
  height: calc(10px * var(--scale-coefficient));
}

.resize-panel > .main-container + div > div {
  position: absolute;
  user-select: none;
  width: 100%;
  height: calc(10px * var(--scale-coefficient));
  top: 0px;
  left: 0px;
  cursor: row-resize;
}
