.popover-menu {
    max-width: 500px;
    min-width: calc(100px * var(--scale-coefficient));
}

.popover-menu__loading {
    padding: calc(20px * var(--scale-coefficient));
    display: flex;
    align-items: center;
    justify-content: center;
}

.popover-menu__loading svg {
    max-height: none !important;
    max-width: none !important;
}

.popover-menu__icon {
    width: calc(20px * var(--scale-coefficient));
    height: calc(20px * var(--scale-coefficient));
    margin-right: calc(10px * var(--scale-coefficient));
    display: flex;
    justify-content: center;
}

.popover-menu__title {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: calc(16px * var(--scale-coefficient));
    color: var(--black);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.popover-menu__item {
    min-width: calc(186px * var(--scale-coefficient));
    padding: calc(8px * var(--scale-coefficient)) calc(12px * var(--scale-coefficient));
    display: flex;
    align-items: center;
    cursor: pointer;
    user-select: none;
}

.popover-menu__item:hover, .popover-menu__item_selected {
    background: rgba(196, 196, 196, 0.15);
}

.popover-menu__item:not(.popover-menu__item_delete):hover svg, .popover-menu__item_selected:not(.popover-menu__item_delete) svg {
    fill: var(--primary-color);
}

.popover-menu__item_disabled {
    pointer-events: none;
    opacity: 0.5;
}

.popover-menu__divider {
    height: calc(8px * var(--scale-coefficient));
    width: 100%;
}

.popover-menu__divider:after {
    content: '';
    position: absolute;
    top: calc(4px * var(--scale-coefficient));
    left: calc(6px * var(--scale-coefficient));
    height: 1px;
    width: calc(100% - 12px * var(--scale-coefficient));
    background-color: #DAE7ED;
}

.popover-menu__children {
    background: var(--white);
    border: 1px solid var(--medium-cyan);
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.06), 0px 4px 10px rgba(58, 130, 228, 0.12);
    border-radius: var(--base-border-radius);
    padding: 2px;
    position: absolute;
    left: 100%;
    top: 0;

}

.popover-menu__arrow-icon {
    display: flex;
    margin-left: auto;
}

.popover-menu__arrow-icon svg {
    fill: var(--dark-grey);
}

.popover-menu__item_word-wrap .popover-menu__title {
    white-space: normal;
}
