.filtration-panel > div {
  display: flex;
}

.filtration-panel__side {
  width: 320px;
  border-right: 1px solid var(--light-grey);
}

.filtration-panel__side_top {
  border-bottom: 1px solid var(--light-grey);
  padding: calc(12px * var(--scale-coefficient)) calc(16px * var(--scale-coefficient));
}

.filtration-panel__side_bottom {
  padding: 0 calc(10px * var(--scale-coefficient)) 0 calc(16px * var(--scale-coefficient));
  margin-right: calc(5px * var(--scale-coefficient));
  margin-top: calc(20px * var(--scale-coefficient));
  overflow-y: scroll;
  height: 300px;
}

.filtration-panel__main {
  padding: calc(12px * var(--scale-coefficient)) calc(16px * var(--scale-coefficient));
  width: 572px
}

.filtration-panel__buttons {
  padding: calc(12px * var(--scale-coefficient)) calc(16px * var(--scale-coefficient));
  border-top: 1px solid var(--light-grey);
}

.filtration-panel__textarea {
  height: 100%;
}
