.wrap {
    padding: calc(20px * var(--scale-coefficient));
    font-family: Roboto, Arial, sans-serif;
}

.title {
     margin-bottom: calc(16px * var(--scale-coefficient));
     font-family: Manrope, sans-serif;
     font-style: normal;
     font-weight: bold;
     font-size: calc(16px * var(--scale-coefficient));
     line-height: calc(18px * var(--scale-coefficient));
     color: var(--black);
 }
