.loader-controls {
    &__container {
        display: flex;
         align-items: center;
         padding: calc(20px * var(--scale-coefficient)) 0;
     }
    &__buttons-container {
        white-space: nowrap;
        display: flex;
        align-items: center;
    }
    &__buttons-container > button {
         margin-left: calc(16px * var(--scale-coefficient));
     }

    &__title {
         margin-right: auto;
         font-family: 'Manrope';
         font-style: normal;
         font-weight: 500;
         font-size: calc(24px * var(--scale-coefficient));
         line-height: calc(28px * var(--scale-coefficient));
         color: var(--title-color);
         overflow: hidden;
         text-overflow: ellipsis;
         white-space: nowrap;
    }
}
