.source-field {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background: var(--white);
  margin-left: 0;
  margin-right: 0;
  margin-bottom: calc(14px * var(--scale-coefficient));
  border: 1px solid var(--dark-cyan);
  border-radius: var(--base-border-radius);
  box-shadow: 0px 4px 10px rgba(58, 130, 228, 0.12), 0px 4px 10px rgba(0, 0, 0, 0.06);
padding: calc(6px * var(--scale-coefficient)) calc(10px * var(--scale-coefficient));

  &__title {
    font-family: Roboto, Arial, sans-serif !important;
    font-style: normal;
    font-weight: 600;
    font-size: calc(14px * var(--scale-coefficient));
    line-height: calc(14px * var(--scale-coefficient));
    color: var(--title-color);
    width: 100%;
    margin-top: auto;
    margin-bottom: auto;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__rename {
    outline: none;
    border: none;
    border-bottom: var(--primary-color) 1px solid;
    display: flex;
    flex-grow: 1;
    padding-bottom: 5px;
    font-style: normal;
    font-weight: 600;
    font-size: calc(14px * var(--scale-coefficient));
    font-family: Roboto, Arial, sans-serif;
    color: var(--title-color);
    padding-top: calc(5px * var(--scale-coefficient));
 }

  &__type-icon {
    margin-right: calc(15px * var(--scale-coefficient));
  }

  &__tick-mark {
    box-shadow: none;
    border: none;
    margin: 0;
  }

  &__error {
    color: red;
  }

  &__icons {
    display: flex;
    align-items: center;
  }
    &__icons > * {
         margin-right: calc(6px * var(--scale-coefficient));
     }
    &__icons > *:last-child {
         margin-right: 0;
     }

  &__tooltip-title {
    font-size: calc(14px * var(--scale-coefficient));
    font-family: 'Roboto';
    font-weight: 600;
    color: white;
  }

  &__delete-icon {
    padding-bottom: calc(4px * var(--scale-coefficient));
  }

  &__save-icon {
    top: calc(2px * var(--scale-coefficient));
  }

  &_blue {
    color: var(--primary-color) !important;
    box-shadow: none;
  }
}
