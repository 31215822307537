.sql-script-form {
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  .MuiFormControl-root {
    flex-grow: 1;

    .MuiInputBase-root {
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      height: 0;

      .MuiInputBase-input {
        flex-grow: 1;
      }
    }
  }

  &__notification-container {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }

  &__buttons {
    display: flex;
    margin-top: auto;

    .button-text {
      color: #444;
      &:hover {
        color: #444;
      }
    }
  }
}

