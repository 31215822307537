.user-info {
    display: flex;
    height: 100%;
    border-left: 1px solid var(--light-grey);
    width: calc(303px * var(--scale-coefficient));
    min-width: calc(303px * var(--scale-coefficient));
    align-items: center;
    margin-left: calc(20px * var(--scale-coefficient));
    padding: 5px calc(20px * var(--scale-coefficient));
}

.user-info__icon {
    width: calc(46px * var(--scale-coefficient));
    height: calc(46px * var(--scale-coefficient));
    border: 1px solid var(--light-grey);
    border-radius: var(--base-border-radius);
    background: var(--light-cyan);
    display: flex;
    align-items: center;
    justify-content: center;
}

.user-info__icon svg {
    max-width: none !important;
    max-height: none !important;
    opacity: 0.2;
}

.user-info__logo-container {
    width: calc(46px * var(--scale-coefficient));
    height: calc(46px * var(--scale-coefficient));
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: var(--base-border-radius);
}

.user-info__logo {
    max-height: 100%;
    max-width: 100%;
    border-radius: var(--base-border-radius);
}

.user-info__name {
    margin: 0 calc(20px * var(--scale-coefficient));
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: calc(14px * var(--scale-coefficient));
}

.user-info__logout {
    cursor: pointer;
    margin-left: auto;
    color: var(--dark-grey);
    width: calc(30px * var(--scale-coefficient));
    height: calc(30px * var(--scale-coefficient));
}

.user-info__logout svg {
    width: calc(26px * var(--scale-coefficient)) !important;
    height: calc(26px * var(--scale-coefficient)) !important;
    max-width: none !important;
    max-height: none !important;
}

@media screen and (max-width: 900px) {
    .user-info {
        max-width: calc(265px * var(--scale-coefficient));
        min-width: calc(265px * var(--scale-coefficient));
    }
}

@media screen and (max-width: 600px) {
    .user-info {
        margin-left: 7px;
        padding-left: 0;
    }

    .user-info__logo-container {
        width: calc(20px * var(--scale-coefficient));
        height: calc(20px * var(--scale-coefficient));
    }
}
