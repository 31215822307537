.rollback-table {
  background: var(--white);
}

.rollback .MuiTableCell-body {
  color: var(--title-color) !important;
}

.rollback-table__table {
  border: 1px solid var(--dark-cyan);
  border-collapse: separate;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px
}

.rollback-table__head {
  background: #EDF1F8;
  height: calc(48px * var(--scale-coefficient));
}

.rollback-table__head th:first-child {
  border-top-left-radius: 8px;
}

.rollback-table__head th:last-child {
  border-top-right-radius: 8px;
}

.rollback-table th:last-child, .rollback-table td:last-child {
  max-width: calc(220px * var(--scale-coefficient));
}

.rollback-table__cell {
  padding: calc(8px * var(--scale-coefficient)) calc(28px * var(--scale-coefficient));
  border-bottom: 1px solid var(--dark-cyan);
}

.rollback-table__link {
  text-decoration: none;
}

.rollback-table__row:last-child .rollback-table__cell {
  border-bottom: none;
}

.rollback-table__cell > div {
  display: flex;
  align-items: center;
}

.rollback-table__row {
  cursor: pointer;
  color: inherit;
}

.rollback-table__row_active {
  background: #94baee;
}

.rollback-table__cell-value {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: calc(14px * var(--scale-coefficient));
  line-height: calc(18px * var(--scale-coefficient));
}

.rollback-table__cell_head .rollback-table__cell-value {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: calc(14px * var(--scale-coefficient));
  line-height: calc(18px * var(--scale-coefficient));
  color: inherit;
}

.rollback-table__cell_head {
  padding-top: 0;
  padding-bottom: 0;
}

.rollback-table__cell-sort {
  margin-left: calc(8px * var(--scale-coefficient));
}

.rollback-table__cell-icon {
  margin-right: calc(8px * var(--scale-coefficient));
  display: flex;
  align-items: center;
}

.rollback-table__cell_settings > div > * {
  margin-right: calc(16px * var(--scale-coefficient));
}

.rollback-table__cell_settings > div > *:last-child {
  margin-right: 0;
}

.rollback-table__row:last-child {
  border-bottom: none;
}

.rollback-table__cell_first .rollback-table__cell-value {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: calc(14px * var(--scale-coefficient));
  line-height: calc(18px * var(--scale-coefficient));
}

.rollback-table__link {
  color: inherit;
  text-decoration: inherit;
}

.rollback-info {
  font-weight: 500;
}