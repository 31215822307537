.widget-carousel {
  height: var(--panel-size);
  background-color: var(--white);
  z-index: 15;
  border: 1px solid #dae7ed;
  padding: 5px calc(15px * var(--scale-coefficient));
  display: flex;
  align-items: center;
  overflow: hidden;
}

.widget-carousel--deep {
  z-index: 1;
}

.widget-carousel a, .widget-carousel-item a {
  text-decoration: none;
}

.widget-carousel__container {
  display: flex;
  overflow: hidden;
  width: 100%;
  border-right: 1px solid var(--dark-cyan);
  padding-right: calc(12px * var(--scale-coefficient));
  margin-right: calc(12px * var(--scale-coefficient));
}

.widget-carousel__dropdown {
  display: flex;
  flex-grow: 1;
  min-width: 0;
}

.widget-carousel__dropdown-no-data {
  display: flex;
  align-items: center;
  color: var(--dark-grey);
  font-family: Roboto, Arial, sans-serif;
  white-space: nowrap;
  min-width: 0;
}

.widget-carousel__dropdown-no-data span {
  margin-left: 10px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: calc(100% - 32px);
  flex-shrink: 0;
  flex-grow: 1;
}

.widget-footer__button {
  border: 1px solid var(--dark-cyan);
  box-sizing: border-box;
  border-radius: var(--base-border-radius);
  background: none;
  cursor: pointer;
  margin: 0 calc(5px * var(--scale-coefficient));
  padding: calc(9px * var(--scale-coefficient));
  outline: none;
  height: calc(46px * var(--scale-coefficient));
  min-width: calc(46px * var(--scale-coefficient));
  white-space: nowrap;
  display: flex;
  align-items: center;
}

.widget-footer__button_icon {
  margin: 0 calc(5px * var(--scale-coefficient));
}

.widget-footer__button span {
  font-family: Roboto, sans-serif;
  margin: 0 5px 0 5px;
  color: var(--title-color);
  white-space: nowrap;
  vertical-align: middle;
  font-size: calc(14px * var(--scale-coefficient));
}

.widget-footer__button.no-margin-content span {
  margin: 0;
}

.widget-carousel-item__inline_flex {
  flex-grow: 1;
  display: inline-flex;
  width: calc(100% - 100px);
  overflow: hidden;
}

.widget-carousel-item {
  position: relative;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  padding-right: calc(12px * var(--scale-coefficient));
  background: var(--white);
  min-width: 200px;
  transition: all 0.5s;
  margin: 0;
  flex-grow: 1;
  height: calc(46px * var(--scale-coefficient));

  border: 1px solid var(--dark-cyan);
  box-sizing: border-box;

  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.06),
    0 4px 10px rgba(58, 130, 228, 0.12);
  border-radius: var(--base-border-radius);
}

.widget-carousel-item:hover {
  background: #edf1f8;
  border: 1px solid var(--primary-color);
  box-sizing: border-box;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.06),
    0 4px 10px rgba(58, 130, 228, 0.12);
}

.widget-carousel-item > a {
  flex-grow: 1;
  padding: calc(8px * var(--scale-coefficient)) 0 calc(8px * var(--scale-coefficient)) calc(12px * var(--scale-coefficient));
  display: flex;
  align-items: center;
}

.widget-carousel-item__active {
  border: 1px solid var(--primary-color);
  box-sizing: border-box;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.06),
  0px 4px 10px rgba(58, 130, 228, 0.12);
  border-radius: var(--base-border-radius);
  background: #edf1f8;
}

.widget-carousel-item__label, .widget-carousel-item__block-label {
  margin-left: calc(10px * var(--scale-coefficient));
  line-height: normal;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  display: inline-block;
  vertical-align: middle;
  color: var(--title-color);
}

.widget-carousel__content {
  display: flex;
  flex-grow: 1;
  width: calc(100% - 310px);
  flex-shrink: 0;
}

.widget-carousel__project-link {
  border-right: 1px solid var(--dark-cyan);
  padding-right: calc(12px * var(--scale-coefficient));
  margin-right: calc(12px * var(--scale-coefficient));
}

.widget-footer__popper-container {
  z-index: 15;
}

.widget-footer__popper-item-container {
  overflow: auto;
}

.widget-footer__popper-title {
  padding: calc(10px * var(--scale-coefficient));
  width: 100%;
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
}

.widget-footer__popper-title span {
  font-family: Roboto, sans-serif;
  color: var(--dark-grey);
  font-size: calc(14px * var(--scale-coefficient));
  margin-right: calc(6px * var(--scale-coefficient));
}

.widget-footer__title {
  flex-grow: 1;
  font-family: Manrope, sans-serif;
  font-weight: bold;
  color: var(--black);
  font-size: calc(16px * var(--scale-coefficient));
}

.widget-footer__popper-item {
  display: flex;
  flex-grow: 1;
  margin: calc(10px * var(--scale-coefficient));
}

.widget-footer__popper {
  padding: 5px;
  min-width: calc(330px * var(--scale-coefficient));
}

.widget-carousel a:hover .widget-carousel-item {
  border: 1px solid var(--primary-color);
  box-sizing: border-box;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.06),
  0 4px 10px rgba(58, 130, 228, 0.12);
  border-radius: 3px;
}

.widget-carousel-arrow {
  margin-left: calc(10px * var(--scale-coefficient));
  margin-right: calc(10px * var(--scale-coefficient));
}

.carousel-modal {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  outline: none;
}

.carousel-modal__content {
  outline: none;
}

.carousel-modal__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.carousel-modal__close {
  cursor: pointer;
  border-radius: 4px;
  padding: 12px;
  outline: none;
  border: 1px solid var(--dark-cyan);
  transition: all 0.3s;
}

.carousel-modal__close:hover {
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2),
    0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
  background-color: #d5d5d5;
}

.carousel-modal__title {
  font-size: 18px;
  line-height: 1;
  color: #444;
}

.carousel-modal__descr {
  font-size: 14px;
  line-height: 1;
  color: #444;
  margin: 16px 0 20px;
}

.carousel-modal__content {
  background: var(--white);
  border: 1px solid var(--dark-cyan);
  border-radius: 4px;
  padding: 20px;
  width: 400px;
  box-sizing: border-box;
}

.carousel-modal__buttons {
  display: flex;
  align-items: center;
}

.carousel-modal__buttons button {
  flex-basis: 40%;
}

.carousel-modal__buttons button:first-child {
  flex-basis: 60%;
  margin-right: 10px;
}

.carousel-modal__field {
  margin-bottom: 20px;
}

.widget-carousel__tootip-value {
  display: none;
  padding: 0;
  width: 0;
  height: 0;
  overflow: hidden;
}

.widget-footer__search-container {
  margin: calc(10px * var(--scale-coefficient));
  width: calc(100% - 20px * var(--scale-coefficient));
}

.widget-carousel__project-link .widget-carousel-item {
  min-width: 185px;
}


@media screen and (max-width: 900px) {
  .widget-carousel__project-link .widget-carousel-item {
    min-width: 160px;
  }
}

@media screen and (max-width: 1200px) {
  .widget-carousel-item__inline_flex .widget-carousel-item {
    min-width: 70px;
  }
}

@media screen and (max-width: 1050px) {
  .widget-footer__button-text {
    display: none;
  }
}

@media screen and (max-width: 670px) {
  .widget-carousel-item__inline_flex {
    display: none;
  }
  .widget-footer__button {
    flex: 1;
  }
  .widget-carousel-arrow {
    margin-left: auto;
  }
}
