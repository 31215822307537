.widget-tooltip__container {
    position: fixed;
    z-index: 1400;
    background-color: rgba(68, 68, 68, 0.9);
    border-radius: 5px;
    color: white;
}

.widget-tooltip__title, .widget-tooltip__value {
    font-size: calc(14px * var(--scale-coefficient));
    line-height: calc(16px * var(--scale-coefficient));
    font-family: Roboto, sans-serif;
    font-style: normal;
}

.widget-tooltip__title {
    font-weight: 400;
}

.widget-tooltip__value {
    font-weight: 500;
}
