.bound-content-title__container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: calc(10px * var(--scale-coefficient)) calc(10px * var(--scale-coefficient)) calc(10px * var(--scale-coefficient)) calc(20px * var(--scale-coefficient));
}

.bound-content-title__title {
    font-family: Manrope, sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: calc(14px * var(--scale-coefficient));
}

.bound-content-title__button-text {
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: calc(14px * var(--scale-coefficient));
}

.bound-content-title__button-icon svg {
    vertical-align: top;
}
