.dashboard-content {
  display: flex;
  height: calc(100vh - 2 * var(--panel-size));
}

.dashboard-main {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  background-color: var(--backdrop-color);
}

.dashboard-main:before {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: -webkit-repeating-radial-gradient(center center, var(--backdrop-item-color), var(--backdrop-item-color) 1px, transparent 2px, transparent 100%);
  -webkit-background-size: calc(24px * var(--scale-coefficient)) calc(24px * var(--scale-coefficient));
  -moz-background-size: calc(24px * var(--scale-coefficient)) calc(24px * var(--scale-coefficient));
  background-size: calc(24px * var(--scale-coefficient)) calc(24px * var(--scale-coefficient));
}

.dashboard-layout {
  display: flex;
  flex-direction: column;
}

.layout__no-header .dashboard-content {
  height: calc(100vh - 2 * var(--panel-size));
}
