.column-coloring-options-dialog__list {
    max-height: 50vh;
    overflow-y: auto;
    overflow-x: hidden;
    padding-right: calc(8px * var(--scale-coefficient));
    margin-right: calc(-8px * var(--scale-coefficient));
}

.column-coloring-options-dialog__main {
    padding: calc(12px * var(--scale-coefficient)) calc(16px * var(--scale-coefficient));
}

.column-coloring-options-dialog__empty {
    text-align: center;
    width: 100%;
}

.column-coloring-options-dialog__empty-description {
    font-family: Roboto;
    font-style: normal;
    font-weight: 400;
    font-size: calc(16px * var(--scale-coefficient));
    line-height: calc(19px * var(--scale-coefficient));
}

.column-coloring-options-dialog__buttons-container {
    display: flex;
    width: 100%;
    padding: calc(12px * var(--scale-coefficient)) calc(16px * var(--scale-coefficient));
    border-top: 1px solid var(--light-grey);
}

.column-coloring-options-dialog__buttons-container .column-coloring-options-dialog__buttons-container_right {
    margin-left: auto;
}

.column-coloring-options-dialog__buttons-container > button:first-child {
    margin-right: calc(16px * var(--scale-coefficient));
}

.column-coloring-options-dialog__add-button {
    margin-left: calc(100px * var(--scale-coefficient));
}

.column-coloring-options-dialog__add-button-title {
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: calc(14px * var(--scale-coefficient));
    line-height: calc(18px * var(--scale-coefficient));
    text-transform: none;
}

.conditional-coloring__container {
    background: #EDF1F8;
    padding: calc(20px * var(--scale-coefficient));
    margin-bottom: calc(20px * var(--scale-coefficient));
    border-radius: var(--base-border-radius);
}

.conditional-coloring__container:last-child {
    margin-bottom: 0;
}

.conditional-coloring__head {
    display: flex;
    margin-bottom: calc(20px * var(--scale-coefficient));
    align-items: center;
}

.conditional-coloring__head-close {
    margin-bottom: 0;
    align-items: baseline;
}

.conditional-coloring__head svg {
    vertical-align: middle;
    cursor: pointer;
    transition: all 0.3s;
}

.conditional-coloring_open-button {
    transform: rotate(180deg);
}

.conditional-coloring__title {
    margin-left: calc(12px * var(--scale-coefficient));
    font-family: Manrope;
    font-style: normal;
    font-weight: bold;
    font-size: calc(14px * var(--scale-coefficient));
    line-height: calc(16px * var(--scale-coefficient));
    color: var(--black);
    max-width: calc(400px * var(--scale-coefficient));
    text-overflow: ellipsis;
    overflow: hidden;
}

.conditional-coloring-head__right {
    margin-left: auto;
    display: flex;
}

.conditional-coloring-head__right > * {
    margin-left: calc(20px * var(--scale-coefficient));
}

.conditional-coloring__square {
    border: 1px solid var(--light-grey);
    border-radius: 2px;
    width: calc(40px * var(--scale-coefficient));
    height: calc(24px * var(--scale-coefficient));
    text-align: center;
}

.conditional-coloring__flex {
    display: flex;
    justify-content: space-between;
    margin-bottom: calc(20px * var(--scale-coefficient));
}

.conditional-coloring__flex > div:not(:last-child) {
    margin-right: calc(10px * var(--scale-coefficient));
}

.conditional-coloring__colors {
    display: flex;
    margin-top: calc(15px * var(--scale-coefficient));
    width: 100%;
}

.conditional-coloring__formula-container {
    margin: calc(15px * var(--scale-coefficient)) 0 0;
}

.conditional-coloring__color-container:first-child {
    margin-right: calc(10px * var(--scale-coefficient));
}

.column-coloring-options-dialog__error-container {
    margin-bottom: calc(12px * var(--scale-coefficient));
    max-width: calc(604px * var(--scale-coefficient));
}

.conditional-coloring__select_big {
    flex-basis: 48%;
}

.conditional-coloring__select_middle {
    flex-basis: 24%;
}
